import { useState } from 'react';
import { Link, useSearchParams, useNavigate } from 'react-router-dom';

import messages from './messages';

import { login } from '../../api';
import { stripReturnUrl, validateEmail } from '../../core/helper';

import { ErrorAlert } from '../Common/Alert';
import Button from '../Common/Button';
import { TextInput } from '../Common/Form';
import { injectIntl } from 'react-intl';
import LoadingDialog from '../Common/Loading/LoadingDialog';
import { AuthHeading } from './Heading';
import Footer from '../Common/Footer';
import AuthContainer from './AuthContainer';

let LoginComp = ({ intl: { formatMessage } }) => {
  let [searchParams] = useSearchParams();

  const [formData, setFormData] = useState({
    email: '',
    pass: '',
    errorMessage: '',
  });
  const [sending, setSending] = useState(false);

  const navigate = useNavigate();

  const onFormSubmit = async (event) => {
    event.preventDefault();
    let returnUrlModified = stripReturnUrl(searchParams);
    const { email, pass } = formData;

    if (!validateEmail(email)) {
      setFormData((prev) => ({
        ...prev,
        errorMessage: formatMessage(messages.EnterValidEmail),
      }));
      return;
    }

    setSending(true);

    try {
      await login(email, pass, returnUrlModified);
    } catch (error) {
      setFormData((prev) => ({
        ...prev,
        errorMessage: formatMessage(messages.WrongCombination),
      }));
    }

    setSending(false);
  };

  const loginWithEmail = () => {
    if (!validateEmail(formData.email)) {
      setFormData((prev) => ({
        ...prev,
        errorMessage: formatMessage(messages.EnterValidEmail),
      }));
      return;
    }
    navigate(`/login-link-request/${formData.email}`);
  };

  return (
    <>
      <LoadingDialog show={sending} />
      <AuthContainer>
        <AuthHeading msg={formatMessage(messages.WelcomeBack)} />

        <div className="mt-8 ">
          <div className="">
            <form className="space-y-6" method="POST" onSubmit={onFormSubmit}>
              <div>
                <TextInput
                  text={formatMessage(messages.Email)}
                  id="email"
                  name="email"
                  type="email"
                  autoComplete="email"
                  required
                  onChange={(e) => {
                    setFormData({
                      ...formData,
                      email: e.target.value,
                      errorMessage: null,
                    });
                  }}
                  value={formData.email}
                />
              </div>
              <div>
                <TextInput
                  text={formatMessage(messages.Pass)}
                  id="pass"
                  name="pass"
                  type="password"
                  autoComplete="current-password"
                  required
                  onChange={(e) => {
                    setFormData({
                      ...formData,
                      pass: e.target.value,
                      errorMessage: null,
                    });
                  }}
                  value={formData.pass}
                />
              </div>
              <div className="flex flex-row justify-between">
                <div className="flex items-center mr-3">
                  <div className="flex items-center w-full text-center">
                    <input
                      id="remember-me"
                      name="remember-me"
                      type="checkbox"
                      className="w-4 h-4 border-gray-300 rounded text-poltio-blue-500 focus:ring-poltio-blue-500"
                    />
                    <label
                      htmlFor="remember-me"
                      className="block ml-2 text-sm text-gray-900"
                    >
                      {formatMessage(messages.RememberMe)}
                    </label>
                  </div>
                </div>
                <div className="flex items-center justify-between ml-3">
                  <div className="w-full text-sm text-center">
                    <Link
                      to="/password-forgot"
                      className="font-medium text-poltio-blue-600 hover:text-poltio-blue-500"
                    >
                      {formatMessage(messages.Forgot)}
                    </Link>
                  </div>
                </div>
              </div>
              <Button.Primary
                type="submit"
                className="w-full"
                disabled={sending}
              >
                {formatMessage(messages.SignIn)}
              </Button.Primary>

              {formData.errorMessage ? (
                <ErrorAlert title={formData.errorMessage} />
              ) : null}
            </form>

            <div className="mt-6">
              <div className="relative">
                <div className="absolute inset-0 flex items-center">
                  <div className="w-full border-t border-gray-300" />
                </div>
                <div className="relative flex justify-center text-sm">
                  <span className="px-2 text-gray-500 bg-white">
                    {formatMessage(messages.StartTrial)}
                  </span>
                </div>
              </div>

              <Button.Secondary
                className="flex justify-center w-full mt-4"
                disabled={sending}
                href={`/signup${window.location.search}`}
              >
                {formatMessage(messages.CreateNew)}
              </Button.Secondary>
            </div>
            <p className="mt-10 text-center text-sm text-gray-500">
              <button
                onClick={loginWithEmail}
                className="font-semibold leading-6 text-poltio-blue-600 hover:text-poltio-blue-500"
              >
                {formatMessage(messages.ClickLogin1)}
              </button>{' '}
              {formatMessage(messages.ClickLogin2)}
            </p>
          </div>
          <Footer />
        </div>
      </AuthContainer>
    </>
  );
};
LoginComp = injectIntl(LoginComp);

const Login = () => <LoginComp />;

export default Login;
