import { useState, useEffect, useCallback, useContext, useMemo } from 'react';
import { useLocation } from 'react-router-dom';
import { useIntl, injectIntl } from 'react-intl';
import { getReportRequests, setReportRequest } from '../../api';

import Loading from '../Common/Loading';
import Pagination from '../Common/Pagination';
import SearchRow from './SearchRow';
import { NotificationsCTX } from '../../contexts/Notification';
import { ArrowDownTrayIcon } from '@heroicons/react/24/solid';
import makeReportRequestPayload from './reportTypes';
import Heading from '../Common/Page/Heading';
import messages from './messages';

function linkFormatter(cell) {
  if (!cell) return null;

  return (
    <a
      href={`https://s3-eu-west-1.amazonaws.com/poltio-reports/${cell}`}
      target="_blank"
      rel="noopener noreferrer"
      className="flex flex-row"
    >
      {cell}
      <ArrowDownTrayIcon
        className="w-5 h-5 mr-4 ml-1 text-gray-900"
        aria-hidden="true"
      />
    </a>
  );
}

function TH({ text, className = '' }) {
  return (
    <th
      scope="col"
      className={`px-6 py-3 text-xs font-medium tracking-wider text-left text-gray-500 uppercase ${className}`}
    >
      {text}
    </th>
  );
}

function ReportsTBody({ data }) {
  const intl = useIntl();

  return (
    <tbody>
      {data.map((report, i) => (
        <tr key={i} className={i % 2 === 0 ? 'bg-white' : 'bg-gray-50'}>
          <td className="hidden px-6 py-6 text-sm text-gray-500 whitespace-nowrap lg:flex">
            <div className="text-sm font-medium text-gray-900">{report.id}</div>
          </td>
          <td className="px-6 py-4 text-sm text-gray-500 whitespace-nowrap">
            <div className="text-sm font-medium text-gray-900">
              {linkFormatter(report.report_file)}
            </div>
          </td>
          <td className="px-6 py-4 text-sm text-gray-500 whitespace-nowrap">
            <div className="text-sm font-medium text-gray-900">
              {report.status}
            </div>
          </td>
          <td className="px-6 py-4 text-sm text-gray-500 whitespace-nowrap">
            <div className="text-sm font-medium text-gray-900">
              {report.runtime}
            </div>
          </td>
          <td className="px-6 py-4 text-sm text-gray-500 whitespace-nowrap">
            <div className="text-sm font-medium text-gray-900">
              <span>{intl.formatDate(report.created_at)}</span>{' '}
              <span>{intl.formatTime(report.created_at)}</span>
            </div>
          </td>
          <td className="px-6 py-4 text-sm text-gray-500 whitespace-nowrap">
            <div className="text-sm font-medium text-gray-900">
              <span>{intl.formatDate(report.updated_at)}</span>{' '}
              <span>{intl.formatTime(report.updated_at)}</span>
            </div>
          </td>
        </tr>
      ))}
    </tbody>
  );
}

function ReportsTable({ data, formatMessage }) {
  return (
    <div className="max-w-7xl mx-auto px-2 sm:px-6 md:px-8">
      <div className="flex flex-col py-4">
        <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
          <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
            <div className="overflow-hidden border-b border-gray-200 shadow sm:rounded-lg">
              <table className="min-w-full divide-y divide-gray-200">
                <thead className="bg-gray-50">
                  <tr>
                    <TH text="ID" className="hidden lg:flex" />
                    <TH text={formatMessage(messages.File)} />
                    <TH text={formatMessage(messages.Status)} />
                    <TH text={formatMessage(messages.RunTime)} />
                    <TH text={formatMessage(messages.CreatedAt)} />
                    <TH text={formatMessage(messages.UpdatedAt)} />
                  </tr>
                </thead>
                <ReportsTBody data={data} />
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

const Reports = ({ intl: { formatMessage } }) => {
  const location = useLocation();
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [pageData, setPageData] = useState({
    totalSize: 100,
    page: 1,
    sizePerPage: 12,
    lastPage: null,
  });

  const reportData = useMemo(() => location?.state, [location?.state]);
  const [typeValue, setType] = useState(reportData?.report || null);
  const [idValue, setID] = useState(
    reportData?.public_id || reportData?.base_id || ''
  );
  const [downloading, setDownloading] = useState(false);
  const [initialDownload, setInitialDownload] = useState(
    reportData?.report && (reportData?.public_id || reportData?.base_id)
      ? true
      : false
  );

  const { success, error } = useContext(NotificationsCTX);

  const fetchData = useCallback(async () => {
    setLoading(true);
    const { data } = await getReportRequests(
      pageData.page,
      pageData.sizePerPage
    );
    setData(data.data);
    setLoading(false);
    setPageData((p) => {
      return {
        ...p,
        page: p.page,
        sizePerPage: p.sizePerPage,
        totalSize: data.total,
        lastPage: data.last_page,
      };
    });
  }, [pageData.page, pageData.sizePerPage]);

  const handlePageChange = (page, sizePerPage) => {
    setPageData({
      ...pageData,
      page,
      sizePerPage,
    });
  };

  useEffect(() => {
    if (!reportData?.report || !reportData?.public_id) {
      fetchData();
    }
  }, [fetchData, reportData?.report, reportData?.public_id]);

  const downloadRequest = useCallback(async () => {
    if (!idValue || !typeValue) return;
    setDownloading(true);
    setInitialDownload(false);
    try {
      await setReportRequest(makeReportRequestPayload(typeValue, idValue));

      success('Report request submitted.');

      setDownloading(false);
      fetchData();

      window.history.replaceState({}, document.title);
    } catch (e) {
      error('Error occured!');
      setDownloading(false);
    }
  }, [error, success, idValue, typeValue, fetchData]);

  useEffect(() => {
    if (initialDownload && !downloading) {
      downloadRequest();
    }
  }, [initialDownload, downloading, downloadRequest]);

  return (
    <div>
      <div className="max-w-7xl mx-auto px-2 sm:px-6 md:px-8">
        <Heading
          title={formatMessage(messages.Reports)}
          body={formatMessage(messages.ReportsDesc)}
        />
      </div>
      <div className="flex items-end max-w-7xl mx-auto px-2 sm:px-6 md:px-8">
        <SearchRow
          downloadRequest={downloadRequest}
          downloading={downloading}
          setType={setType}
          setID={setID}
          typeValue={typeValue}
          idValue={idValue}
        />
      </div>

      <div className="max-w-7xl mx-auto px-2 sm:px-6 md:px-8">
        <Pagination
          last={pageData.lastPage}
          active={pageData.page}
          size={pageData.sizePerPage}
          handlePageChange={handlePageChange}
          totalSize={pageData.totalSize}
          showTotal
        />
      </div>
      {loading ? (
        <div className="max-w-7xl mx-auto px-2">
          <Loading />
        </div>
      ) : (
        <>
          <ReportsTable formatMessage={formatMessage} data={data} />
          <div className="max-w-7xl mx-auto px-2 sm:px-6 md:px-8">
            <Pagination
              last={pageData.lastPage}
              active={pageData.page}
              size={pageData.sizePerPage}
              handlePageChange={handlePageChange}
            />
          </div>
        </>
      )}
    </div>
  );
};

export default injectIntl(Reports);
