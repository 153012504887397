import React from 'react';

import EditorContentDetail from './ContentDetail';
import EditorPreview from './Preview';
import Settings from './Settings';

const EditorContent = () => (
  <div className="grid h-full">
    <div className="sm:flex sm:flex-row h-full">
      <EditorContentDetail />

      <div className="sm:flex sm:flex-col w-full sm:h-full mb-4 sm:mb-0">
        <EditorPreview />
      </div>

      <Settings />
    </div>
  </div>
);

export default EditorContent;
