/* eslint-disable no-undef */
import { Component, Suspense } from 'react';
import * as Sentry from '@sentry/react';

import { BrowserRouter, Route, Routes } from 'react-router-dom';
import 'react-image-crop/dist/ReactCrop.css';

import history from '../core/history';

import { NotificationsContainer } from '../contexts/Notification';

import Navigation from './Navigation';
import TermsAndConditions from './Auth/Terms';

import PrivateRoutes from './Common/Routing';
import Login from './Auth/Login';
import ForgotPassword from './Auth/ForgotPassword';
import Signup from './Auth/Signup';
import VerifyAccount from './Auth/VerifyAccount';
import ResetPassword from './Auth/ResetPassword';
import Loading from './Common/Loading';
import LoginLinkRequest from './Auth/LoginLinkRequest';
import LoginWithEmail from './Auth/LoginWithEmail';

history.listen(() => {
  window.scrollTo(0, 0);
});

class App extends Component {
  componentDidCatch(error, errorInfo) {
    Sentry.withScope((scope) => {
      scope.setExtras(errorInfo);
      Sentry.captureException(error);
    });
  }

  render() {
    return (
      <NotificationsContainer>
        <BrowserRouter>
          <main>
            <Routes>
              <Route
                path="/login-link-request/:email"
                element={<LoginLinkRequest />}
              />
              <Route
                path="/login-with-email/:email/:token"
                element={<LoginWithEmail />}
              />
              <Route path="/login" element={<Login />} />
              <Route path="/password-forgot" element={<ForgotPassword />} />
              <Route path="/signup" element={<Signup />} />
              <Route
                path={'/verify-account/:email/:resetCode'}
                element={<VerifyAccount />}
              />
              <Route
                path={'/password-reset/:email/:resetCode'}
                element={<ResetPassword />}
              />
              <Route
                path={'/terms-and-conditions'}
                element={<TermsAndConditions />}
              />
              <Route
                path="/*"
                element={
                  <Suspense fallback={<Loading />}>
                    <Navigation>
                      <PrivateRoutes />
                    </Navigation>
                  </Suspense>
                }
              />
            </Routes>
          </main>
        </BrowserRouter>
      </NotificationsContainer>
    );
  }
}

export default App;
