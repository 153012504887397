import { Switch } from '@headlessui/react';

function classNames(...classes) {
  return classes.filter(Boolean).join(' ');
}

const SwitchInput = ({ value, onSwitch, children, description, disabled }) => {
  return (
    <>
      <div className="flex items-center py-1.5 w-full justify-between">
        <div>{!!children && children}</div>
        <div className="flex justify-end px-4">
          <Switch
            checked={value}
            disabled={disabled}
            onChange={onSwitch}
            className={classNames('poltio-switch-btn', value ? 'on' : 'off')}
          >
            <span className="switch-ball"></span>
          </Switch>
        </div>
      </div>
      {description && (
        <div className="text-xs mb-2 mr-4 text-gray-400">{description}</div>
      )}
    </>
  );
};

export { SwitchInput };
export default SwitchInput;
