import React, { useState, useEffect } from 'react';
import { injectIntl } from 'react-intl';
import logo from '../../../images/poltio-logo.svg';
import { CheckCircleIcon, XCircleIcon } from '@heroicons/react/24/solid';
import { useParams } from 'react-router-dom';

import Button from '../../Common/Button';
import Loading from '../../Common/Loading';
import { verify } from '../../../api';
import messages from '../messages';

const VerifyAccount = ({ intl: { formatMessage } }) => {
  const [loading, setLoading] = useState(true);
  const [hasError, setHasError] = useState(false);

  const { email, resetCode } = useParams();

  useEffect(() => {
    async function verifyEmail() {
      if (email && resetCode) {
        try {
          await verify(email, resetCode);
          setLoading(false);
        } catch (e) {
          setLoading(false);
          setHasError(true);
        }
      }
    }
    verifyEmail();
  }, [email, resetCode]);

  return loading ? (
    <Loading />
  ) : (
    <div className="flex flex-col justify-center min-h-screen py-12 bg-alice-blue sm:px-6 lg:px-8">
      <div className="max-w-2xl mx-auto text-center py-16 px-4 sm:py-20 sm:px-6 lg:px-8">
        <img className="w-auto h-12 mx-auto" src={logo} alt="Workflow" />
        {hasError ? (
          <div>
            <XCircleIcon
              className="h-20 w-20 text-red-400 mx-auto mt-6"
              aria-hidden="true"
            />
            <p className="mt-4 text-lg leading-6">
              {formatMessage(messages.VaError)}
            </p>
          </div>
        ) : (
          <div>
            <CheckCircleIcon
              className="h-20 w-20 text-green-400 mx-auto mt-6"
              aria-hidden="true"
            />
            <p className="mt-4 text-lg leading-6">
              {formatMessage(messages.VaSuccess)}
            </p>
          </div>
        )}

        <div className="mt-8 w-full inline-flex items-center justify-center px-5 py-3 sm:w-auto">
          <Button.Primary type="submit" href={`/`} disabled={hasError}>
            {formatMessage(messages.ReturnHome)}
          </Button.Primary>
        </div>
      </div>
    </div>
  );
};

export default injectIntl(VerifyAccount);
