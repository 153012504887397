import { ChevronRightIcon } from '@heroicons/react/24/outline';
import { useState } from 'react';
import { useLocation, Link } from 'react-router-dom';
import messages from './messages';
import { injectIntl } from 'react-intl';

function classNames(...classes) {
  return classes.filter(Boolean).join(' ');
}

const NavButton = ({ item = {}, onClick, canCollapse, formatMessage }) => {
  const [expanded, setExpanded] = useState(false);
  const location = useLocation();

  return (
    <>
      {item.path ? (
        <Link
          onClick={(e) => {
            if (onClick) {
              onClick(e, item.path);
            }
          }}
          to={item.path}
          className={classNames(
            'btn group',
            item.path === location.pathname
              ? item.currentClassName ?? 'is-current'
              : '',
            item.className
          )}
        >
          <div className="relative flex items-center w-full overflow-hidden">
            <div className="px-2 py-2">
              {item?.icon ? (
                <item.icon className="h-6 w-6" aria-hidden="true" />
              ) : null}
            </div>
            <span className="absolute left-10 text-sm font-medium w-48 text-left">
              <div className="flex justify-between">
                <div>{formatMessage(messages[item.name])}</div>
              </div>
            </span>
          </div>
        </Link>
      ) : (
        <button
          onClick={(e) => {
            if (onClick) {
              onClick(e);
            }
            setExpanded(!expanded);
          }}
          className={classNames(
            'btn group',
            item.path === location.pathname
              ? item.currentClassName ?? 'is-current'
              : '',
            item.className
          )}
        >
          <div className="relative flex items-center w-full overflow-hidden">
            <div className="px-2 py-2">
              {item?.icon ? (
                <item.icon className="h-6 w-6" aria-hidden="true" />
              ) : null}
            </div>
            <span className="absolute left-10 text-sm font-medium w-48 text-left">
              <div className="flex justify-between">
                <div>{formatMessage(messages[item.name])}</div>
                <div>
                  {item.expandable ? (
                    <ChevronRightIcon
                      className={classNames(
                        !expanded ? 'rotate-90 text-gray-500' : 'text-gray-400',
                        'ml-auto h-5 w-5 shrink-0'
                      )}
                      aria-hidden="true"
                    />
                  ) : null}
                </div>
              </div>
            </span>
          </div>
        </button>
      )}
      {expanded ? (
        <div
          className={classNames(
            canCollapse ? 'lg:hidden lg:group-hover:flex' : 'flex',
            'flex-column flex-wrap gap-y-2'
          )}
        >
          {item?.children?.map((i, ind) => (
            <Link
              key={ind}
              onClick={(e) => {
                if (i.element) {
                  if (onClick) {
                    onClick(e, i.path);
                  }
                }
              }}
              to={i.path}
              target={i.element ? '_self' : '_blank'}
              rel="noreferrer"
              className={classNames(
                'btn group',
                'py-2',
                i.path === location.pathname
                  ? i.currentClassName ?? 'is-current'
                  : '',
                i.className
              )}
            >
              <div className="relative flex items-center w-full overflow-hidden">
                <div className="px-2 py-2"></div>
                <span className="absolute left-10 text-sm font-sm w-48 text-left">
                  {formatMessage(messages[i.name])}
                </span>
              </div>
            </Link>
          ))}
        </div>
      ) : null}
    </>
  );
};

export default injectIntl(NavButton);
