import { Fragment, useState, useCallback, useEffect } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import { getInputs } from '../../api/leads';
import { useNavigate } from 'react-router-dom';
import { injectIntl } from 'react-intl';

import Pagination from '../Common/Pagination';
import Button from '../Common/Button';
import Loading from '../Common/Loading';
import messages from './messages';
import { iconTypes } from '../../core/constants';

const IncomingLeads = ({
  open = false,
  setOpen,
  activeLead,
  intl: { formatMessage, formatDate },
}) => {
  function classNames(...classes) {
    return classes.filter(Boolean).join(' ');
  }

  const [pageData, setPageData] = useState({
    totalSize: 100,
    page: 1,
    sizePerPage: 50,
    lastPage: null,
  });
  const [leads, setLeads] = useState([]);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const fetchData = useCallback(async () => {
    setLoading(true);
    if (activeLead.id) {
      const { data } = await getInputs(
        activeLead.id,
        pageData.page,
        pageData.sizePerPage
      );
      setLeads(data.data);
      setPageData((p) => {
        return {
          ...p,
          page: p.page,
          sizePerPage: p.sizePerPage,
          totalSize: data.total,
          lastPage: data.last_page,
        };
      });
      setLoading(false);
    }
  }, [pageData.page, pageData.sizePerPage, activeLead]);

  useEffect(() => {
    const data = activeLead;
    if (data) {
      try {
        fetchData(pageData.page, pageData.sizePerPage);
      } catch (e) {
        navigate(`/leads`);
      }
    } else {
      navigate(`/leads`);
    }
  }, [activeLead, pageData.page, pageData.sizePerPage, fetchData, navigate]);

  const handlePageChange = (page, sizePerPage) => {
    setPageData({
      ...pageData,
      page,
      sizePerPage,
    });
  };

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog
        as="div"
        className="fixed z-10 inset-0 overflow-y-auto"
        onClose={setOpen}
      >
        <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          {/* This element is to trick the browser into centering the modal contents. */}
          <span
            className="hidden sm:inline-block sm:align-middle sm:h-screen"
            aria-hidden="true"
          >
            &#8203;
          </span>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enterTo="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          >
            <div className="inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-6xl sm:w-full sm:p-6">
              <div>
                <Pagination
                  last={pageData.lastPage}
                  active={pageData.page}
                  size={pageData.sizePerPage}
                  handlePageChange={handlePageChange}
                  totalSize={pageData.totalSize}
                  showTotal
                />
                {loading ? (
                  <div className="max-w-7xl mx-auto px-2">
                    <Loading />
                  </div>
                ) : (
                  <Fragment>
                    <div className="flex flex-col py-4">
                      <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                        <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
                          <div className="overflow-hidden border-b border-gray-200 shadow sm:rounded-lg">
                            <table className="min-w-full divide-y divide-gray-200">
                              <thead className="bg-gray-50">
                                <tr>
                                  <th
                                    scope="col"
                                    className="px-6 py-3 text-xs font-medium tracking-wider text-left text-gray-500 uppercase"
                                  >
                                    {formatMessage(messages.UserId)}
                                  </th>
                                  <th
                                    scope="col"
                                    className="px-6 py-3 text-xs font-medium tracking-wider text-left text-gray-500 uppercase"
                                  >
                                    {formatMessage(messages.ContentType)}
                                  </th>
                                  <th
                                    scope="col"
                                    className="px-6 py-3 text-xs font-medium tracking-wider text-left text-gray-500 uppercase"
                                  >
                                    {formatMessage(messages.ContentId)}
                                  </th>
                                  <th
                                    scope="col"
                                    className="px-6 py-3 text-xs font-medium tracking-wider text-left text-gray-500 uppercase"
                                  >
                                    {formatMessage(messages.Name)}
                                  </th>
                                  <th
                                    scope="col"
                                    className="px-6 py-3 text-xs font-medium tracking-wider text-left text-gray-500 uppercase"
                                  >
                                    {formatMessage(messages.Email)}
                                  </th>
                                  <th
                                    scope="col"
                                    className="px-6 py-3 text-xs font-medium tracking-wider text-left text-gray-500 uppercase"
                                  >
                                    {formatMessage(messages.Comment)}
                                  </th>
                                  <th
                                    scope="col"
                                    className="px-6 py-3 text-xs font-medium tracking-wider text-left text-gray-500 uppercase"
                                  >
                                    {formatMessage(messages.CreatedAt)}
                                  </th>
                                  <th
                                    scope="col"
                                    className="px-6 py-3 text-xs font-medium tracking-wider text-left text-gray-500 uppercase"
                                  >
                                    {formatMessage(messages.Phone)}
                                  </th>
                                  <th
                                    scope="col"
                                    className="px-6 py-3 text-xs font-medium tracking-wider text-left text-gray-500 uppercase"
                                  >
                                    {formatMessage(messages.PlayTime)}
                                  </th>
                                  <th
                                    scope="col"
                                    className="px-6 py-3 text-xs font-medium tracking-wider text-left text-gray-500 uppercase"
                                  >
                                    {formatMessage(messages.CorrectAnswer)}
                                  </th>
                                  <th
                                    scope="col"
                                    className="px-6 py-3 text-xs font-medium tracking-wider text-left text-gray-500 uppercase"
                                  >
                                    {formatMessage(messages.CalculatorScore)}
                                  </th>
                                  <th
                                    scope="col"
                                    className="px-6 py-3 text-xs font-medium tracking-wider text-left text-gray-500 uppercase"
                                  >
                                    {'Completion'}
                                  </th>
                                </tr>
                              </thead>
                              <tbody>
                                {leads.map((l, i) => (
                                  <tr
                                    key={i}
                                    className={
                                      i % 2 === 0 ? 'bg-white' : 'bg-gray-50'
                                    }
                                  >
                                    <td className="px-6 py-4 text-sm text-gray-500 whitespace-nowrap">
                                      <div className="text-sm font-medium text-gray-900">
                                        {l.user_id}
                                      </div>
                                    </td>
                                    <td className="px-6 pl-4 pr-2 text-sm text-gray-500 whitespace-nowrap">
                                      <div className="text-sm font-medium text-gray-900">
                                        {l.content_type}
                                      </div>
                                    </td>

                                    <td className="px-6 pl-4 pr-2 text-sm text-gray-500 whitespace-nowrap">
                                      <div className="text-sm font-medium text-gray-900">
                                        {l.content_id}
                                      </div>
                                    </td>
                                    <td className="px-6 pl-4 pr-2 text-sm text-gray-500 whitespace-nowrap">
                                      <div className="text-sm font-medium text-gray-900">
                                        {l.name}
                                      </div>
                                    </td>
                                    <td className="px-6 pl-4 pr-2 text-sm text-gray-500 whitespace-nowrap">
                                      <div className="text-sm font-medium text-gray-900">
                                        {l.email}
                                      </div>
                                    </td>
                                    <td className="px-6 pl-4 pr-2 text-sm text-gray-500 whitespace-nowrap">
                                      <div className="text-sm font-medium text-gray-900">
                                        {l.comment}
                                      </div>
                                    </td>
                                    <td className="px-6 pl-4 pr-2 text-sm text-gray-500 whitespace-nowrap">
                                      <div className="text-sm font-medium text-gray-900">
                                        {formatDate(l.created_at)}
                                      </div>
                                    </td>
                                    <td className="px-6 pl-4 pr-2 text-sm text-gray-500 whitespace-nowrap">
                                      <div className="text-sm font-medium text-gray-900">
                                        {l.phone}
                                      </div>
                                    </td>
                                    <td className="px-6 pl-4 pr-2 text-sm text-gray-500 whitespace-nowrap">
                                      <div className="text-sm font-medium text-gray-900">
                                        {l.session.play_time ?? 'N/A'}
                                      </div>
                                    </td>
                                    <td className="px-6 pl-4 pr-2 text-sm text-gray-500 whitespace-nowrap">
                                      <div className="text-sm font-medium text-gray-900">
                                        {l.session.right_answer ?? 'N/A'}
                                      </div>
                                    </td>
                                    <td className="px-6 pl-4 pr-2 text-sm text-gray-500 whitespace-nowrap">
                                      <div className="text-sm font-medium text-gray-900">
                                        {l.session.score ?? 'N/A'}
                                      </div>
                                    </td>
                                    <td className="px-6 pl-4 pr-2 text-sm text-gray-500 whitespace-nowrap">
                                      <div className="flex-shrink-0">
                                        <svg
                                          className={classNames(
                                            l.session.finished_at
                                              ? 'text-green-400'
                                              : 'text-red-400',
                                            'h-5 w-5'
                                          )}
                                          xmlns="http://www.w3.org/2000/svg"
                                          viewBox="0 0 20 20"
                                          fill="currentColor"
                                          aria-hidden="true"
                                        >
                                          <path
                                            fillRule="evenodd"
                                            d={
                                              iconTypes[
                                                l.session.finished_at
                                                  ? 'success'
                                                  : 'error'
                                              ]
                                            }
                                            clipRule="evenodd"
                                          />
                                        </svg>
                                      </div>
                                    </td>
                                  </tr>
                                ))}
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </div>
                    </div>
                    <Pagination
                      last={pageData.lastPage}
                      active={pageData.page}
                      size={pageData.sizePerPage}
                      handlePageChange={handlePageChange}
                      totalSize={pageData.totalSize}
                    />
                  </Fragment>
                )}
              </div>
              <div className="mt-5 sm:mt-6 flex justify-center">
                <div>
                  <Button.Primary
                    type="button"
                    onClick={() =>
                      navigate(`/reports`, {
                        state: {
                          base_id: `${activeLead.id}`,
                          report: `voter-leads`,
                        },
                      })
                    }
                  >
                    {formatMessage(messages.Download)}
                  </Button.Primary>
                </div>
                <div className="ml-5">
                  <Button.Secondary
                    type="button"
                    className="w-full"
                    onClick={() => setOpen(false)}
                  >
                    {formatMessage(messages.Close)}
                  </Button.Secondary>
                </div>
              </div>
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>
  );
};

export default injectIntl(IncomingLeads);
