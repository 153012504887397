const DetailCard = ({
  title,
  subtitle,
  children,
  datesChanged,
  startDate,
  endDate,
}) => {
  return (
    <div className="space-y-6 lg:col-start-1 lg:col-span-2">
      <section aria-labelledby="applicant-information-title">
        <div className="bg-white shadow rounded-lg">
          <div className="px-4 py-5 sm:px-6 sm:flex w-full">
            <div className="w-full">
              <h2
                id="applicant-information-title"
                className="text-lg font-medium text-gray-900 leading-6"
              >
                {title}
              </h2>
              <p className="max-w-2xl mt-1 text-sm text-gray-500">{subtitle}</p>
            </div>
            {datesChanged ? (
              <div className="flex sm:grid gap-x-2 self-end sm:justify-items-end text-xs w-full sm:w-1/4 text-right mt-4 sm:mt-0 sm:pr-4 text-gray-500">
                <span className="inline-flex items-center rounded-md bg-gray-50 px-2 py-1 text-xs font-normal text-gray-500 ring-1 ring-inset ring-gray-500/10">
                  {startDate}
                </span>
                <span className="sm:mt-1 inline-flex items-center rounded-md bg-gray-50 px-2 py-1 text-xs font-normal text-gray-500 ring-1 ring-inset ring-gray-500/10">
                  {endDate}
                </span>
              </div>
            ) : null}
          </div>
          <div className="px-4 py-5 border-t border-gray-200 sm:px-6">
            {children}
          </div>
        </div>
      </section>
    </div>
  );
};

export default DetailCard;
