import { logout } from '../../api/index';
import { feedAvatarPathRetina } from '../../core/constants';
import LanguageSelect from './LanguageSelect';

function classNames(...classes) {
  return classes.filter(Boolean).join(' ');
}

const Avatar = ({ user, goToRoute, messages, formatMessage, ring }) => (
  <div className="flex-shrink-0 flex border-t border-gray-200 p-2 min-h-20">
    <div className="flex flex-grow items-center overflow-hidden relative">
      <div
        className="flex-grow cursor-pointer"
        onClick={(e) => goToRoute(e, '/settings')}
      >
        {user?.photo ? (
          <img
            className={classNames(
              'h-10 w-10 rounded-full',
              !!ring && 'border border-poltio-blue-600'
            )}
            src={`${feedAvatarPathRetina}/${user?.photo}`}
            alt=""
          />
        ) : null}
      </div>
      <div className="absolute left-10 ml-3 w-64">
        <p
          className="text-sm font-medium text-gray-700 group-hover:text-gray-900 cursor-pointer"
          onClick={(e) => goToRoute(e, '/settings')}
        >
          {user?.username}
        </p>
        <p className="text-xs font-medium text-gray-500">
          Account ID: {user?.id}
        </p>
        <p className="text-xs font-medium text-gray-500 group-hover:text-gray-700">
          <a href="" onClick={() => logout()}>
            {formatMessage(messages.Signout)}
          </a>
          {' - '}
          <LanguageSelect />
        </p>
      </div>
    </div>
  </div>
);

export default Avatar;
