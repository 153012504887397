import { defineMessages } from 'react-intl.macro';

export default defineMessages({
  Width: {
    id: 'EmbedSettings.Width',
    defaultMessage: 'Width',
  },
  Height: {
    id: 'EmbedSettings.Height',
    defaultMessage: 'Height',
  },
  DisplayShare: {
    id: 'EmbedSettings.DisplayShare',
    defaultMessage: 'Display Share Buttons',
  },
  DisplayDisclaimer: {
    id: 'EmbedSettings.DisplayDisclaimer',
    defaultMessage: 'Display Disclaimer',
  },
  ResultFit: {
    id: 'EmbedSettings.ResultFit',
    defaultMessage: 'Result Fit',
  },
  Off: {
    id: 'EmbedSettings.Off',
    defaultMessage: 'Off',
  },
  ResultImageCrop: {
    id: 'EmbedSettings.ResultImageCrop',
    defaultMessage: 'Result Image Crop',
  },
  Fit: {
    id: 'EmbedSettings.Fit',
    defaultMessage: 'Fit',
  },
  Alignment: {
    id: 'EmbedSettings.Alignment',
    defaultMessage: 'Horizontal Alignment',
  },
  Left: {
    id: 'EmbedSettings.Left',
    defaultMessage: 'Left',
  },
  Center: {
    id: 'EmbedSettings,Center',
    defaultMessage: 'Center',
  },
  Right: {
    id: 'EmbedSettings.Right',
    defaultMessage: 'Right',
  },
  VerticalAlignment: {
    id: 'EmbedSettings.VerticalAlignment',
    defaultMessage: 'Vertical Alignment',
  },
  Top: {
    id: 'EmbedSettings.Top',
    defaultMessage: 'Top',
  },
  Bottom: {
    id: 'EmbedSettings.Bottom',
    defaultMessage: 'Bottom',
  },
  Language: {
    id: 'EmbedSettings.Language',
    defaultMessage: 'Language',
  },
  DeviceLanguage: {
    id: 'EmbedSettings,DeviceLanguage',
    defaultMessage: 'Device Language',
  },
  English: {
    id: 'EmbedSettings.English',
    defaultMessage: 'English',
  },
  Turkish: {
    id: 'EmbedSettings.Turkish',
    defaultMessage: 'Turkish',
  },
  German: {
    id: 'EmbedSettings.German',
    defaultMessage: 'German',
  },
  French: {
    id: 'EmbedSettings.French',
    defaultMessage: 'French',
  },
  Italian: {
    id: 'EmbedSettings.Italian',
    defaultMessage: 'Italian',
  },
  Spanish: {
    id: 'EmbedSettings.Spanish',
    defaultMessage: 'Spanish',
  },
  Hebrew: {
    id: 'EmbedSettings.Hebrew',
    defaultMessage: 'Hebrew',
  },
  Romanian: {
    id: 'EmbedSettings.Romanian',
    defaultMessage: 'Romanian',
  },
  Dutch: {
    id: 'EmbedSettings.Dutch',
    defaultMessage: 'Dutch',
  },
  Portuguese: {
    id: 'EmbedSettings.Portuguese',
    defaultMessage: 'Portuguese',
  },
  Thai: {
    id: 'EmbedSettings.Thai',
    defaultMessage: 'Thai',
  },
  Arabic: {
    id: 'EmbedSettings.Arabic',
    defaultMessage: 'Arabic',
  },
  Hungarian: {
    id: 'EmbedSettings.Hungarian',
    defaultMessage: 'Hungarian',
  },
  Austrian: {
    id: 'EmbedSettings.Austrian',
    defaultMessage: 'Austrian',
  },
  Algerian: {
    id: 'EmbedSettings.Algerian',
    defaultMessage: 'Algerian',
  },
  Egyptian: {
    id: 'EmbedSettings.Egyptian',
    defaultMessage: 'Egyptian',
  },
  Moroccan: {
    id: 'EmbedSettings.Moroccan',
    defaultMessage: 'Moroccan',
  },
  UtmSource: {
    id: 'EmbedSettings.UtmSource',
    defaultMessage: 'Utm Source',
  },
  UtmDesc: {
    id: 'EmbedSettings.UtmDesc',
    defaultMessage:
      'Identify the advertiser, site, publication, etc. that is sending traffic to your property, for example: google, newsletter4, billboard.',
  },
  UtmMedium: {
    id: 'EmbedSettings.UtmMedium',
    defaultMessage: 'Utm Medium',
  },
  UtmMDesc: {
    id: 'EmbedSettings.UtmMDesc',
    defaultMessage:
      'The advertising or marketing medium, for example: cpc, banner, email newsletter.',
  },
  UtmCampaign: {
    id: 'EmbedSettings.UtmCampaign',
    defaultMessage: 'Utm Campaign',
  },
  UtmCDesc: {
    id: 'EmbedSettings.UtmCDesc',
    defaultMessage:
      'The individual campaign name, slogan, promo code, etc. for a product.',
  },
  MobileView: {
    id: 'EmbedSettings.MobileView',
    defaultMessage: 'Mobile View',
  },
  Options: {
    id: 'EmbedSettings.Options',
    defaultMessage: 'Options',
  },
  VerticalFit: {
    id: 'EmbedSettings.VerticalFit',
    defaultMessage: 'Vertical Fit',
  },

  SameWindow: {
    id: 'EmbedSettings.SameWindow',
    defaultMessage: 'Open the result link in the same window',
  },
  DontUseCookie: {
    id: 'EmbedSettings.DontUseCookie',
    defaultMessage: `Don't Use Cookie`,
  },
  UrlSuccess: {
    id: 'EmbedSettings.UrlSuccess',
    defaultMessage: 'Url successfully copied',
  },
  UrlError: {
    id: 'EmbedSettings.UrlError',
    defaultMessage: 'Error copying Url!',
  },
  EmbedOptions: {
    id: 'EmbedSettings.EmbedOptions',
    defaultMessage: 'Embed Options',
  },
  Integrations: {
    id: 'EmbedSettings.Integrations',
    defaultMessage: 'Integrations',
  },
  DontUseResize: {
    id: 'EmbedSettings.DontUseResize',
    defaultMessage: "Don't use auto resize",
  },
  CustomID: {
    id: 'EmbedSettings.CustomID',
    defaultMessage: 'Custom ID',
  },
  DirectLink: {
    id: 'EmbedSettings.DirectLink',
    defaultMessage: 'Direct Link',
  },
  Open: {
    id: 'EmbedSettings.Open',
    defaultMessage: 'Open',
  },
  SelfDesc: {
    id: 'EmbedSettings.SelfDesc',
    defaultMessage: 'The current browsing context.',
  },
  BlankDesc: {
    id: 'EmbedSettings.BlankDesc',
    defaultMessage:
      'Usually a new tab, but users can configure browsers to open a new window instead.',
  },
  ParentDesc: {
    id: 'EmbedSettings.ParentDesc',
    defaultMessage:
      'The parent browsing context of the current one. If no parent, behaves as _self.',
  },
  TopDesc: {
    id: 'EmbedSettings.TopDesc',
    defaultMessage: `The topmost browsing context (the "highest" context that's an ancestor of the current one). If no ancestors, behaves as _self.`,
  },
  ResultUrl: {
    id: 'EmbedSettings.ResultUrl',
    defaultMessage: 'Result URL Window Settings',
  },
  EmbedCode: {
    id: 'EmbedSettings.EmbedCode',
    defaultMessage: 'Embed Code',
  },
  SaveSettings: {
    id: 'EmbedSettings.SaveSettings',
    defaultMessage: 'Save Settings',
  },
  CopyCode: {
    id: 'EmbedSettings.CopyCode',
    defaultMessage: 'Copy Code',
  },
  Close: {
    id: 'EmbedSettings.Close',
    defaultMessage: 'Close',
  },
  ButtonText: {
    id: 'EmbedSettings.ButtonText',
    defaultMessage: 'Button Text',
  },
  ButtonColor: {
    id: 'EmbedSettings.ButtonColor',
    defaultMessage: 'Button Color',
  },
  ButtonTextColor: {
    id: 'EmbedSettings.ButtonTextColor',
    defaultMessage: 'Button Text Color',
  },
  SlideoverColor: {
    id: 'EmbedSettings.SlideoverColor',
    defaultMessage: 'Slideover Color',
  },
  ButtonPosition: {
    id: 'EmbedSettings.ButtonPosition',
    defaultMessage: 'Select Button Position',
  },
  SlideoverPosition: {
    id: 'EmbedSettings.SlideoverPosition',
    defaultMessage: 'Select Slideover Position',
  },
  SelectDomain: {
    id: 'EmbedSettings.SelectDomain',
    defaultMessage: 'Select your Domain',
  },
  FloatingSlideoverWidget: {
    id: 'EmbedSettings.FloatingSlideoverWidget',
    defaultMessage: 'Floating Slideover Widget',
  },
  ButtonZIndex: {
    id: 'EmbedSettings.ButtonZIndex',
    defaultMessage: 'Button Z Index',
  },
  SlideoverZIndex: {
    id: 'EmbedSettings.SlideoverZIndex',
    defaultMessage: 'Slideover Z Index',
  },
  HideButton: {
    id: 'EmbedSettings.HideButton',
    defaultMessage: 'Hide Button',
  },
  ButtonVisibility: {
    id: 'EmbedSettings.ButtonVisibility',
    defaultMessage: 'Button Visibility',
  },
});
