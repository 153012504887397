import React from 'react';
import ContentCard from '../Common/ContentCard';

const Contents = ({ contents, fetchContents }) => (
  <div className="grid grid-cols-1 gap-6 sm:grid-cols-2 md:grid-cols-3 ">
    {contents.map((content) => {
      return (
        <ContentCard
          key={content.public_id}
          content={content}
          fetchContents={fetchContents}
        />
      );
    })}
  </div>
);

export default Contents;
