import { Fragment, useState, useMemo } from 'react';
import { injectIntl } from 'react-intl';
import messages from './messages';
import { Menu, Popover, Transition } from '@headlessui/react';
import {
  Squares2X2Icon,
  ListBulletIcon,
  BarsArrowUpIcon,
} from '@heroicons/react/24/solid';

import { FunnelIcon } from '@heroicons/react/24/outline';
import { SearchButton } from '../Common/Button';

const options = [
  { value: 'poll', label: 'Poll' },
  { value: 'set', label: 'Set' },
  { value: 'test', label: 'Test' },
  { value: 'quiz', label: 'Quiz' },
];

function classNames(...classes) {
  return classes.filter(Boolean).join(' ');
}

const Filters = ({
  sortChanged,
  typeChanged,
  contentTypes,
  setSearchText,
  toggleViewType,
  isList,
  intl: { formatMessage },
}) => {
  const [selectedOption, setSelectedOption] = useState(
    formatMessage(messages.Sort)
  );

  const sortOptions = useMemo(
    () => [
      {
        name: formatMessage(messages.RecentlyCreated),
        val: { order: 'created_at', sort: 'desc' },
      },
      {
        name: formatMessage(messages.RecentlyUpdated),
        val: { order: 'updated_at', sort: 'desc' },
      },
      {
        name: formatMessage(messages.MostVoted),
        val: { order: 'vote_count', sort: 'desc' },
      },
      {
        name: formatMessage(messages.MostVoters),
        val: { order: 'voter_count', sort: 'desc' },
      },
    ],
    [formatMessage]
  );
  const [text, setText] = useState('');

  const onSortChanged = (name, val) => {
    sortChanged(val);
    setSelectedOption(name);
  };

  const handleKeyPress = (event) => {
    if (event.key === 'Enter') {
      setSearchText(text);
    }
  };

  return (
    <div>
      <div className="text-center lg:max-w-7xl">
        <section aria-labelledby="filter-heading" className="py-6">
          <div className="sm:flex-1 sm:flex sm:items-center sm:justify-between">
            <div>
              <div className="mt-1 flex rounded-md shadow-sm">
                <div className="relative flex items-stretch flex-grow focus-within:z-10">
                  <input
                    type="text"
                    name="searchtext"
                    id="searchtext"
                    className="focus:ring-poltio-blue-500 focus:border-poltio-blue-500 block w-full rounded-none rounded-l-md sm:text-sm border-gray-300"
                    placeholder={formatMessage(messages.EnterText)}
                    onChange={(e) => setText(e.target.value)}
                    onKeyPress={handleKeyPress}
                  />
                </div>
                <SearchButton
                  onClick={() => setSearchText(text)}
                  notRounded={true}
                />
                <Menu as="div" className="relative -ml-px block">
                  <div>
                    <Menu.Button className="space-x-2 relative inline-flex items-center bg-gray-50 px-4 py-2 text-gray-900 ring-1 ring-inset ring-gray-300 hover:bg-gray-100 focus:z-10 text-md text-sm font-medium">
                      <BarsArrowUpIcon
                        className="my-0.5 h-5 w-5 text-gray-400"
                        aria-hidden="true"
                      />
                      <span>{selectedOption}</span>
                    </Menu.Button>
                  </div>

                  <Transition
                    as={Fragment}
                    enter="transition ease-out duration-100"
                    enterFrom="transform opacity-0 scale-95"
                    enterTo="transform opacity-100 scale-100"
                    leave="transition ease-in duration-75"
                    leaveFrom="transform opacity-100 scale-100"
                    leaveTo="transform opacity-0 scale-95"
                  >
                    <Menu.Items className="origin-top-left absolute left-0 z-10 mt-2 w-40 rounded-md shadow-2xl bg-white ring-1 ring-black ring-opacity-5 focus:outline-none">
                      <div className="py-1">
                        {sortOptions.map((option) => (
                          <Menu.Item key={option.name}>
                            {({ active }) => (
                              <a
                                onClick={() =>
                                  onSortChanged(option.name, option.val)
                                }
                                className={classNames(
                                  active ? 'bg-gray-100' : '',
                                  'block px-4 py-2 text-sm font-medium text-gray-900'
                                )}
                                style={{ cursor: 'pointer' }}
                              >
                                {option.name}
                              </a>
                            )}
                          </Menu.Item>
                        ))}
                      </div>
                    </Menu.Items>
                  </Transition>
                </Menu>
                <Popover.Group className="sm:flex sm:items-baseline sm:space-x-8">
                  <Popover
                    as="div"
                    id="desktop-menu"
                    className="relative -ml-px block h-full"
                  >
                    <div className="h-full">
                      <Popover.Button className="h-full space-x-2 relative inline-flex items-center bg-gray-50 px-4 py-2 text-gray-900 ring-1 ring-inset ring-gray-300 hover:bg-gray-100 focus:z-10 text-md text-sm font-medium">
                        <FunnelIcon
                          className="my-0.5 h-5 w-5 text-gray-400"
                          aria-hidden="true"
                        />
                        <span>{formatMessage(messages.Filter)}</span>
                      </Popover.Button>
                    </div>

                    <Transition
                      as={Fragment}
                      enter="transition ease-out duration-100"
                      enterFrom="transform opacity-0 scale-95"
                      enterTo="transform opacity-100 scale-100"
                      leave="transition ease-in duration-75"
                      leaveFrom="transform opacity-100 scale-100"
                      leaveTo="transform opacity-0 scale-95"
                    >
                      <Popover.Panel className="origin-top-left absolute right-0 mt-2 z-10 bg-white shadow-2xl p-4 ring-1 ring-black ring-opacity-5 focus:outline-none">
                        <form className="space-y-4">
                          {options.map((option, optionIdx) => (
                            <div
                              key={option.value}
                              className="flex items-center"
                            >
                              <input
                                id={`filter-${optionIdx}`}
                                name={option.value}
                                defaultValue={option.value}
                                type="checkbox"
                                className="h-4 w-4 border-gray-300 rounded text-poltio-blue-600 focus:ring-poltio-blue-500"
                                onChange={(e) =>
                                  typeChanged(option.value, e.target.checked)
                                }
                                checked={contentTypes.includes(option.value)}
                              />
                              <label
                                htmlFor={`filter-${optionIdx}`}
                                className="ml-3 pr-6 text-sm font-medium text-gray-900 whitespace-nowrap"
                              >
                                {option.label}
                              </label>
                            </div>
                          ))}
                        </form>
                      </Popover.Panel>
                    </Transition>
                  </Popover>
                </Popover.Group>
                <button
                  type="button"
                  className="relative inline-flex items-center rounded-r-md bg-gray-50 px-2 py-2 text-gray-400 ring-1 ring-inset ring-gray-300 hover:bg-gray-100 focus:z-10 text-md text-sm font-medium"
                  onClick={toggleViewType}
                >
                  {isList ? (
                    <>
                      <span className="sr-only">
                        {formatMessage(messages.ViewGrid)}
                      </span>
                      <Squares2X2Icon className="w-5 h-5" aria-hidden="true" />
                    </>
                  ) : (
                    <>
                      <span className="sr-only">
                        {formatMessage(messages.ViewList)}
                      </span>
                      <ListBulletIcon className="w-5 h-5" aria-hidden="true" />
                    </>
                  )}
                </button>
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>
  );
};

export default injectIntl(Filters);
