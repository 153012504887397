import { useContext } from 'react';
import TcInfo from '../../Auth/Terms/TcInfo';
import { acceptTc, reSendVerify } from '../../../api';
import { WarningAlert } from '.';
import { NotificationsCTX } from '../../../contexts/Notification';
import { injectIntl } from 'react-intl';
import messages from './messages';
const TcWarning = ({ user, intl: { formatMessage } }) => {
  const onYesClick = async () => {
    await acceptTc();
  };

  const onSendClick = async (e) => {
    e.preventDefault();
    try {
      await reSendVerify();
      success('', formatMessage(messages.Success));
    } catch (e) {
      console.log(e);
    }
  };
  const { success } = useContext(NotificationsCTX);

  return (
    <div>
      {!user?.tc_accepted_at && (
        <div className="max-w-7xl mx-auto px-4 sm:px-6 md:px-8 mb-4">
          <TcInfo onYesClick={onYesClick} />
        </div>
      )}
      {!user?.is_verified && (
        <div className="max-w-7xl mx-auto px-4 sm:px-6 md:px-8 mb-4">
          <WarningAlert
            title={formatMessage(messages.NotVerify)}
            body={<p>{formatMessage(messages.PleaseVerify)}</p>}
            footer={
              <button
                type="button"
                className="bg-yellow-50 px-2 py-1.5 rounded-md text-sm font-medium text-yellow-800 hover:bg-yellow-100 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-yellow-50 focus:ring-yellow-600"
                onClick={onSendClick}
              >
                {formatMessage(messages.ReSend)}
              </button>
            }
          />
        </div>
      )}
    </div>
  );
};

export default injectIntl(TcWarning);
