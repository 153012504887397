import { useState, useEffect, useContext, useCallback, useMemo } from 'react';
import { injectIntl } from 'react-intl';
import { Switch } from '@headlessui/react';

import { useLocation, useNavigate } from 'react-router-dom';

import { getDefaultTheme, saveTheme } from '../../api';

import ThemeField from './ThemeField';
import Loading from '../Common/Loading';
import { NotificationsCTX } from '../../contexts/Notification';
import ImageInput from '../Common/ImageInput';
import { feedImagePathRetina } from '../../core/constants';

import messages from './messages';
import Button from '../Common/Button';
import ThemePreview from './ThemePreview';
import { Dropdown } from '../Common/Form';

function classNames(...classes) {
  return classes.filter(Boolean).join(' ');
}

const ThemeCreateEdit = ({ intl: { formatMessage } }) => {
  const location = useLocation();
  const defaultFields = useMemo(
    () => [
      {
        name: 'primary_color',
        hasColorPicker: true,
        default_desc: formatMessage(messages.primary_color_desc),
      },
      {
        name: 'primary_color_selected',
        hasColorPicker: true,
        default_desc: formatMessage(messages.primary_color_selected_desc),
      },
      {
        name: 'secondary_background_color',
        hasColorPicker: true,
        default_desc: formatMessage(messages.secondary_background_color_desc),
      },
      {
        name: 'primary_text_color',
        hasColorPicker: true,
        default_desc: formatMessage(messages.primary_text_color_desc),
      },
      {
        name: 'primary_text_color_selected',
        hasColorPicker: true,
        default_desc: formatMessage(messages.primary_text_color_selected_desc),
      },
      {
        name: 'secondary_color',
        hasColorPicker: true,
        default_desc: formatMessage(messages.secondary_color_desc),
      },
      {
        name: 'font_option',
        hasColorPicker: false,
        multiple: true,
        default_desc: formatMessage(messages.font_option_default_desc),
        default_value: 'Averta',
        custom_desc: formatMessage(messages.font_option_custom_desc),
      },
      { name: 'font_family', hasColorPicker: false, isChild: 'font_option' },
      {
        name: 'font_family_css',
        hasColorPicker: false,
        isChild: 'font_option',
      },
      {
        name: 'title_font_weight',
        hasColorPicker: false,
        isChild: 'font_option',
      },
      {
        name: 'button_font_weight',
        hasColorPicker: false,
        isChild: 'font_option',
      },
      {
        name: 'button_border_radius',
        hasColorPicker: false,
        default_desc: formatMessage(messages.button_border_radius_desc),
      },
      {
        name: 'answer_border_radius',
        hasColorPicker: false,
        default_desc: formatMessage(messages.answer_border_radius_desc),
      },
      {
        name: 'textinput_border_radius',
        hasColorPicker: false,
        default_desc: formatMessage(messages.textinput_border_radius_desc),
      },
      {
        name: 'font_size_title',
        hasColorPicker: false,
        default_desc: formatMessage(messages.font_size_title_desc),
      },
      {
        name: 'line_height_title',
        hasColorPicker: false,
        default_desc: formatMessage(messages.line_height_title_desc),
      },
      {
        name: 'font_size_answer',
        hasColorPicker: false,
        default_desc: formatMessage(messages.font_size_answer_desc),
      },
      {
        name: 'line_height_answer',
        hasColorPicker: false,
        default_desc: formatMessage(messages.line_height_answer_desc),
      },
      {
        name: 'main_text_color',
        hasColorPicker: true,
        default_desc: formatMessage(messages.main_text_color_desc),
      },
      {
        name: 'background_color',
        hasColorPicker: true,
        default_desc: formatMessage(messages.background_color_desc),
      },
      {
        name: 'cover_background_color',
        hasColorPicker: true,
        default_desc: formatMessage(messages.cover_background_color_desc),
      },
      {
        name: 'cover_text_color',
        hasColorPicker: true,
        default_desc: formatMessage(messages.cover_text_color_desc),
      },
      {
        name: 'cover_desc_color',
        hasColorPicker: true,
        default_desc: formatMessage(messages.cover_desc_color_desc),
      },
      {
        name: 'question_background_color',
        hasColorPicker: true,
        default_desc: formatMessage(messages.question_background_color_desc),
      },
      {
        name: 'question_text_color',
        hasColorPicker: true,
        default_desc: formatMessage(messages.question_text_color_desc),
      },
      {
        name: 'result_background_color',
        hasColorPicker: true,
        default_desc: formatMessage(messages.result_background_color_desc),
      },
      {
        name: 'result_text_color',
        hasColorPicker: true,
        default_desc: formatMessage(messages.result_text_color_desc),
      },
      {
        name: 'result_desc_color',
        hasColorPicker: true,
        default_desc: formatMessage(messages.result_desc_color_desc),
      },
      {
        name: 'mp_info_color',
        hasColorPicker: true,
        default_desc: formatMessage(messages.mp_info_color_desc),
      },
      {
        name: 'positive_color',
        hasColorPicker: true,
        default_desc: formatMessage(messages.positive_color_desc),
      },
      {
        name: 'negative_color',
        hasColorPicker: true,
        default_desc: formatMessage(messages.negative_color_desc),
      },
      {
        name: 'footer_background_color',
        hasColorPicker: true,
        default_desc: formatMessage(messages.footer_background_color_desc),
      },
      {
        name: 'card_shadow',
        hasColorPicker: false,
        default_desc: formatMessage(messages.card_shadow_desc),
      },
      {
        name: 'card_border_radius',
        hasColorPicker: false,
        default_desc: formatMessage(messages.card_border_radius_desc),
      },
      {
        name: 'bar_thickness',
        hasColorPicker: false,
        default_desc: formatMessage(messages.bar_thickness),
      },
      {
        name: 'question_load_time',
        hasColorPicker: false,
        default_desc: formatMessage(messages.question_load_time_desc),
        number: true,
      },
      {
        name: 'gtm_id',
        hasColorPicker: false,
        default_desc: formatMessage(messages.gtm_id_desc),
      },
      {
        name: 'gtm_dl',
        hasColorPicker: false,
        default_desc: formatMessage(messages.gtm_dl_desc),
      },
      {
        name: 'disc_msg',
        hasColorPicker: false,
        default_desc: formatMessage(messages.disc_msg_desc),
      },
      {
        name: 'disc_display_option',
        hasColorPicker: false,
        default_desc: formatMessage(messages.disc_display_option_desc),
        boolean: true,
        switch_desc: formatMessage(messages.disc_switch_desc),
      },
      {
        name: 'show_pb_on_top',
        hasColorPicker: false,
        default_desc: formatMessage(messages.show_pb_on_top_desc),
        boolean: true,
        switch_desc: formatMessage(messages.show_pb_on_top_desc),
      },
      {
        name: 'show_pb_on_bottom',
        hasColorPicker: false,
        default_desc: formatMessage(messages.show_pb_on_bottom_desc),
        boolean: true,
        switch_desc: formatMessage(messages.show_pb_on_bottom_desc),
      },
    ],
    [formatMessage]
  );
  const sizeList = useMemo(
    () => [
      { title: 'auto auto', type: 'auto auto' },
      { title: 'cover', type: 'cover' },
      { title: 'contain', type: 'contain' },
    ],
    []
  );
  const repeatList = useMemo(
    () => [
      { title: 'no-repeat', type: 'no-repeat' },
      { title: 'repeat', type: 'repeat' },
      { title: 'space', type: 'space' },
      { title: 'round', type: 'round' },
    ],
    []
  );
  const [loading, setLoading] = useState(true);
  const [saveDisabled, setSaveDisabled] = useState(false);
  const [theme, setTheme] = useState({});
  const [defaultTheme, setDefaultTheme] = useState({});
  const { error, success } = useContext(NotificationsCTX);
  const [isMobile, setIsMobile] = useState(true);
  const [fields, setFields] = useState({});
  const t = location?.state || {};
  const repeatIndex = repeatList.findIndex((object) => {
    return object.type === theme.background_repeat;
  });
  const sizeIndex = sizeList.findIndex((object) => {
    return object.type === theme.background_size;
  });

  const navigate = useNavigate();

  const getData = useCallback(async () => {
    try {
      const { data: data2 } = await getDefaultTheme();

      setTheme(t.data);
      setTheme((theme) => ({
        ...theme,
        name:
          t.data?.name === 'Poltio Default Theme'
            ? `New Theme ${t.count + 1}`
            : t.data.name,
      }));
      setDefaultTheme(data2);
      setFields(
        defaultFields.map((d) => ({
          ...d,
          name: d.name,
          isDefault: d.multiple
            ? !defaultFields
                .filter((f) => f.isChild === d.name)
                .some((s) => t.data[s.name] !== data2[s.name])
            : t.data[d.name] === data2[d.name],
        }))
      );
      setLoading(false);
    } catch (e) {
      console.log(e);
      error('', formatMessage(messages.Error));
    }
  }, [error, formatMessage, defaultFields, t.data, t.count]);

  useEffect(() => {
    getData();
  }, [getData]);

  const saveChanges = async () => {
    try {
      setSaveDisabled(true);
      const resp = await saveTheme(theme, theme.id);
      if (!theme.id) {
        navigate(`/theme/${resp.data.id}`, { state: { data: resp.data } });
      }
      setSaveDisabled(false);
      success('', formatMessage(messages.Saved));
    } catch (e) {
      setSaveDisabled(false);
      error('', formatMessage(messages.Error));
    }
  };

  const handleTextChange = async (name, val) => {
    setTheme({
      ...theme,
      [name]: val,
    });
  };

  const handleNumberChange = async (name, val, max, min) => {
    setTheme({
      ...theme,
      [name]: Math.max(min, Math.min(max, Number(val))),
    });
  };

  const imageSelected = (image, name) => {
    setTheme({ ...theme, [name]: image });
  };
  const onSizeChange = async (i) => {
    setTheme({ ...theme, background_size: sizeList[i].type });
  };

  const onRepeatChange = async (i) => {
    setTheme({ ...theme, background_repeat: repeatList[i].type });
  };

  const onSwitchChange = async (name, value) => {
    setTheme({ ...theme, [name]: value });
  };

  const fieldChange = (name, val) => {
    setFields(
      fields.map((f) => {
        if (f.name === name) {
          return { ...f, isDefault: val };
        }
        return f;
      })
    );
    if (val) {
      if (fields.filter((fi) => fi.name === name)[0]?.multiple) {
        setTheme({
          ...theme,
          ...fields
            .filter((f) => f.isChild === name)
            .reduce(
              (acc, cur) => ({
                ...acc,
                [cur.name]: defaultTheme[cur.name],
              }),
              {}
            ),
        });
      } else {
        setTheme({
          ...theme,
          [name]: defaultTheme[name],
        });
      }
    }
  };

  return loading ? (
    <Loading />
  ) : (
    <div>
      <div className="max-w-3xl mx-auto sm:px-6 lg:max-w-7xl lg:px-6 lg:grid lg:grid-cols-12">
        <main className="p-2 lg:col-span-9 xl:col-span-6">
          <h1 className="text-2xl font-semibold text-gray-900">
            {formatMessage(messages.Theme)}
          </h1>
          <div className="mt-1">
            <input
              type="text"
              name="name"
              value={theme.name}
              id="name"
              onChange={(e) =>
                handleTextChange(
                  e.target.name,
                  e.target.value.replace(/'/g, '"')
                )
              }
              className="shadow-sm focus:ring-poltio-blue focus:border-poltio-blue block w-full sm:text-sm border-gray-300 rounded-md"
            />
          </div>
          {fields.map((field, index) => (
            <ThemeField
              key={`theme-field-input-key-${index}`}
              field={field}
              fields={fields}
              theme={theme}
              formatMessage={formatMessage}
              messages={messages}
              onChange={handleTextChange}
              onNumberChange={handleNumberChange}
              fieldChange={fieldChange}
              onSwitchChange={onSwitchChange}
              defaultTheme={defaultTheme}
            />
          ))}
          <div></div>
          <div className="h-24 mt-6 grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-6">
            <div className="sm:col-span-3">
              <label
                htmlFor={'Background Size'}
                className="block text-sm font-medium text-gray-700"
              >
                {formatMessage(messages.background_size)}
              </label>
              <span className="text-gray-400 block text-sm">
                {formatMessage(messages.background_size_desc)}
              </span>
              <Dropdown
                data={sizeList}
                selectedIdx={sizeIndex}
                onChange={onSizeChange}
              />
            </div>
            <div className="sm:col-span-3">
              <label
                htmlFor={'Background Repeat'}
                className="block text-sm font-medium text-gray-700"
              >
                {formatMessage(messages.background_repeat)}
              </label>
              <span className="text-gray-400 block text-sm">
                {formatMessage(messages.background_repeat_desc)}
              </span>
              <Dropdown
                data={repeatList}
                selectedIdx={repeatIndex}
                onChange={onRepeatChange}
              />
            </div>
          </div>
          <div className="h-24 mt-6 grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-6">
            <div className="sm:col-span-2">
              <label
                htmlFor={'Embed Background Image'}
                className="block text-sm font-medium text-gray-700"
              >
                {formatMessage(messages.background_img)}
              </label>
              <ImageInput
                title={formatMessage(messages.background_img)}
                callback={(image) => imageSelected(image, 'background_img')}
                src={
                  theme.background_img
                    ? `${feedImagePathRetina}/${theme.background_img}`
                    : ''
                }
                type="feed"
                modal
              />
            </div>
          </div>
          <div className="flex justify-center mt-16">
            <p>{formatMessage(messages.ItMayTake)}</p>
          </div>
          <div className="flex justify-center mt-16 mb-16">
            <Button.Primary
              className="px-6 py-3"
              onClick={saveChanges}
              disabled={saveDisabled}
              showSpinner={saveDisabled}
            >
              {formatMessage(messages.SaveChanges)}
            </Button.Primary>
          </div>
        </main>
        <aside className="hidden xl:block xl:col-span-6">
          <div className="sticky top-6 space-y-4">
            <div className="px-2 sm:px-6">
              <div className="flex items-start justify-between">
                {formatMessage(messages.Preview)}
              </div>
            </div>
            <div className="relative flex-1 px-4 mt-6 sm:px-6">
              <div className="inset-0 px-4 sm:px-6">
                <div className="h-full" aria-hidden="true">
                  <div className="flex items-center justify-between w-full pb-8 top-16">
                    <Switch.Group as="div" className="flex items-center mt-6">
                      <Switch
                        checked={isMobile}
                        onChange={setIsMobile}
                        className={classNames(
                          isMobile ? 'bg-poltio-blue-600' : 'bg-gray-200',
                          'relative inline-flex flex-shrink-0 h-6 w-11 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-poltio-blue-500'
                        )}
                      >
                        <span className="sr-only">
                          {formatMessage(messages.MobileView)}
                        </span>
                        <span
                          aria-hidden="true"
                          className={classNames(
                            isMobile ? 'translate-x-5' : 'translate-x-0',
                            'pointer-events-none inline-block h-5 w-5 rounded-full bg-white shadow transform ring-0 transition ease-in-out duration-200'
                          )}
                        />
                      </Switch>
                      <Switch.Label as="span" className="ml-3">
                        <span className="text-sm font-medium text-gray-900">
                          {formatMessage(messages.MobileView)}
                        </span>
                      </Switch.Label>
                    </Switch.Group>
                  </div>
                  <ThemePreview theme={theme} isMobile={isMobile} />
                </div>
              </div>
            </div>
          </div>
        </aside>
      </div>
    </div>
  );
};

export default injectIntl(ThemeCreateEdit);
