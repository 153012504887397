import { useState, useEffect, useCallback, useContext } from 'react';
import { getDynWidgets, dynWidgetDelete } from '../../api';
import { injectIntl } from 'react-intl';
import { PlusCircleIcon, XMarkIcon } from '@heroicons/react/24/solid';

import Loading from '../Common/Loading';
import Pagination from '../Common/Pagination';
import DynWidgetsTable from './DynWidgetsTable';
import { NotificationsCTX } from '../../contexts/Notification';
import Button from '../Common/Button';
import DynWidgetForm from './DynWidgetForm';
import messages from './messages';
import usePersistentStore from '../../core/usePersistentStore';
import Heading from '../Common/Page/Heading';
import ContentSearch from '../Common/Form/ContentSearch';
import { useNavigate } from 'react-router-dom';

const DynamicWidgets = ({ intl: { formatMessage }, user }) => {
  const [dynWidgets, setdynWidgets] = useState([]);
  const [loading, setLoading] = useState(true);
  const [sizePerPage, setSizePerPage] = usePersistentStore(50, 'spp-wh');
  const [pageData, setPageData] = useState({
    totalSize: 100,
    page: 1,
    lastPage: null,
  });
  const [id, setId] = useState();

  const [open, setOpen] = useState(false);
  const [activeDynWidget, setActiveDynWidget] = useState({});

  const { success, error } = useContext(NotificationsCTX);

  const publicId = window.location.pathname.split('/')[2];

  const navigate = useNavigate();

  const fetchDynWidgets = useCallback(async () => {
    setLoading(true);
    const { data } = await getDynWidgets(
      id || publicId,
      pageData.page,
      sizePerPage
    );
    setdynWidgets(data.data);
    setPageData((p) => {
      return {
        ...p,
        page: p.page,
        totalSize: data.total,
        lastPage: data.last_page,
      };
    });
    setLoading(false);
  }, [pageData.page, sizePerPage, id, publicId]);

  const handlePageChange = (page, sizePerPage) => {
    setSizePerPage(sizePerPage);
    setPageData({ ...pageData, page });
  };

  useEffect(() => {
    fetchDynWidgets();
  }, [fetchDynWidgets]);

  useEffect(() => {
    if (open === false) {
      setActiveDynWidget({});
    }
  }, [open]);

  const handleClickClear = (e) => {
    e.preventDefault();
    navigate(`/widgets`);
  };

  const handleDeleteClick = async (id) => {
    if (window.confirm(formatMessage(messages.SureDelete))) {
      try {
        await dynWidgetDelete(id);
        success(formatMessage(messages.Deleted));
        fetchDynWidgets();
      } catch (e) {
        error(formatMessage(messages.ErrorOccured));
      }
    }
  };

  const onEditClick = (h) => {
    setOpen(true);
    setActiveDynWidget(h);
  };

  const onAddClick = () => {
    setActiveDynWidget({});
    setOpen(true);
  };

  const onSelect = (item) => {
    setId(item.public_id);
  };

  const handleSubmit = () => {
    fetchDynWidgets();
  };

  return (
    <div>
      <div className="max-w-7xl mx-auto px-2 sm:px-6 md:px-8">
        <Heading
          title={formatMessage(messages.DynWidget)}
          titleExtra={`Account ID (pid): ${user?.id}`}
          body={formatMessage(messages.DynWidgetDesc)}
        >
          <div className="w-full md:w-3/5 md:mx-5 mt-2 md:mt-0">
            <ContentSearch publicId={publicId} onSelect={onSelect} />
          </div>
          {publicId ? (
            <div className="md:self-end -ml-4 mr-2">
              <Button.Secondary
                className="p-2.5"
                onClick={(e) => handleClickClear(e)}
              >
                <XMarkIcon className="w-4 h-4" aria-hidden="true" />
              </Button.Secondary>
            </div>
          ) : null}
          <Button.Primary
            className="md:self-end w-full md:w-1/2"
            onClick={onAddClick}
          >
            <PlusCircleIcon className="w-5 h-5 mr-2 -ml-1" aria-hidden="true" />
            {formatMessage(messages.New)}
          </Button.Primary>
        </Heading>
      </div>
      <div className="max-w-7xl mx-auto px-2 sm:px-6 md:px-8">
        <Pagination
          last={pageData.lastPage}
          active={pageData.page}
          size={sizePerPage}
          handlePageChange={handlePageChange}
          totalSize={pageData.totalSize}
          showTotal
        />
      </div>
      {loading ? (
        <div className="max-w-7xl mx-auto px-2">
          <Loading />
        </div>
      ) : (
        <>
          <div className="max-w-7xl mx-auto px-2 sm:px-6 md:px-8">
            <DynWidgetsTable
              dynWidgets={dynWidgets}
              handleDeleteClick={handleDeleteClick}
              openEdit={onEditClick}
            />
          </div>
          <div className="max-w-7xl mx-auto px-2 sm:px-6 md:px-8">
            <Pagination
              last={pageData.lastPage}
              active={pageData.page}
              size={sizePerPage}
              handlePageChange={handlePageChange}
            />
          </div>
        </>
      )}
      <DynWidgetForm
        open={open}
        setOpen={setOpen}
        dynWidget={activeDynWidget}
        success={success}
        error={error}
        handleSubmit={handleSubmit}
        selectedContent={publicId}
        userId={user?.id}
      />
    </div>
  );
};

export default injectIntl(DynamicWidgets);
