import api from '../client';
import { generatePayload } from '../helpers/generatePayload';
import { validatePayload } from '../helpers/validatePayload';
import { ensureARGs } from '../helpers/ensureARGs';

const ContentFields = {
  required: ['title', 'type'],
  allowed: {
    play_once: 'boolean',
    play_once_strategy: 'string',
    play_once_img: 'string',
    play_once_link: 'string',
    play_once_btn: 'string',
    play_once_msg: 'string',
    title: 'string',
    type: 'string',
    background: 'string',
    end_date_day: 'number',
    end_date_hour: 'number',
    end_date_minute: 'number',
    desc: 'string',
    embed_background: 'string',
    skip_start: 'boolean',
    skip_result: 'boolean',
    hide_results: 'boolean',
    hide_counter: 'boolean',
    display_repeat: 'boolean',
    is_searchable: 'boolean',
    is_calculator: 'boolean',
    search_results_per_page: 'number',
    theme_id: 'string,nullable',
    name: 'string,nullable',
    loading_result_label: 'string,nullable',
    loading_next_question_label: 'string,nullable',
    result_loading: 'boolean',
    attributes: {
      show_timer: 'boolean',
      time_limit: 'number',
      pool_question_count: 'number',
      gives_feedback: 'boolean',
      cal_formula: 'string',
      display_results: 'boolean',
      recom_title: 'string',
      noindex: 'boolean',
    },
  },
};

function makePayload(content) {
  validatePayload(content, ContentFields.required);
  return generatePayload(content, ContentFields.allowed);
}

// read call
export function getContent(contentId) {
  return api.get(`/platform/content/${contentId}/edit`);
}

// create & update call
export function saveContent(data, contentId) {
  const payload = makePayload(data);

  if (Object.keys(payload).length === 0) {
    throw Error('PAYLOAD_EMPTY');
  }

  return contentId
    ? api.put(`/platform/content/${contentId}`, payload)
    : api.post('/platform/content/', payload);
}

// attribute calls
export function saveContentLead(contentId, leadId) {
  ensureARGs(contentId);

  return leadId
    ? api.put(`/platform/content/${contentId}/lead`, { lead_id: leadId })
    : api.delete(`/platform/content/${contentId}/lead`);
}

export function saveContentPixelCode(contentId, pixelCodeId) {
  ensureARGs(contentId);

  return pixelCodeId
    ? api.put(`/platform/content/${contentId}/pixel-code`, {
        pixel_code_id: pixelCodeId,
      })
    : api.delete(`/platform/content/${contentId}/pixel-code`);
}

export function saveContentQuestionOrder(contentId, content) {
  ensureARGs(contentId);

  const order = content.questions
    .filter((value) => value?.id)
    .map((value, index) => ({ id: value.id, position: index }));

  if (order.length !== content.questions.length) {
    throw Error('Question ID cannot be empty');
  }

  return api.put(`/platform/content/${contentId}/order/questions`, {
    questions: order,
  });
}
