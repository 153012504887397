import { defineMessages } from 'react-intl.macro';

const messages = defineMessages({
  LeadFormTitle: {
    id: 'Lead.Form.Title',
    defaultMessage: 'Lead',
  },
  PageHeader: {
    id: 'Lead.PageHeader',
    defaultMessage: 'Leads',
  },
  PageHeaderDesc: {
    id: 'Lead.PageHeaderDesc',
    defaultMessage:
      'Leads allows you to collect feedback or personal information from your users or display additional information to them based on their selection.',
  },
  SureDelete: {
    id: 'Lead.SureDelete',
    defaultMessage: 'Are you sure you want to delete this lead ?',
  },
  ErrorOccured: {
    id: 'Lead.ErrorOccured',
    defaultMessage: 'An Error Occured!',
  },
  LeadEmptyNameError: {
    id: 'Lead.Error.EmptyName',
    defaultMessage: 'Lead Name cannot be empty',
  },
  LeadDeleted: {
    id: 'Lead.LeadDeleted',
    defaultMessage: 'Lead Deleted.',
  },
  NewLead: {
    id: 'Lead.New',
    defaultMessage: 'New Lead',
  },
  LeadEditToastTitle: {
    id: 'Lead.Toast.Edit.Title',
    defaultMessage: 'Lead Edited Successfully',
  },
  LeadCreatedToastTitle: {
    id: 'Lead.Toast.Created.Title',
    defaultMessage: 'Lead Created Successfully',
  },
  Input: {
    id: 'Lead.Form.Body.Tabs.InputType',
    defaultMessage: 'Input',
  },
  InputDescription: {
    id: 'Lead.Form.Body.Tabs.InputTypeDescription',
    defaultMessage:
      'Ask the user to input information. For ex: Name, email, phone number or a free input.',
  },
  Redirect: {
    id: 'Lead.Form.Body.Tabs.RedirectType',
    defaultMessage: 'Redirect',
  },
  RedirectDescription: {
    id: 'Lead.Form.Body.Tabs.RedirectTypeDescription',
    defaultMessage:
      'Display a call to action with a clickable button or redirect to another Poltio content automatically.',
  },
  InternalRedirect: {
    id: 'Lead.Form.Body.Tabs.InternalRedirectType',
    defaultMessage: 'Internal Redirect',
  },
  ImageDisplay: {
    id: 'Lead.Form.Body.Tabs.ImageDisplayType',
    defaultMessage: 'Display',
  },
  ImageDisplayDescription: {
    id: 'Lead.Form.Body.Tabs.ImageDisplayTypeDescription',
    defaultMessage:
      'Display a message, image and / or video with no clickable button.',
  },
  Title: {
    id: 'Lead.Form.InputType.Title',
    defaultMessage: 'Title',
  },
  TitleDesc: {
    id: 'Lead.Form.InputType.TitleDesc',
    defaultMessage:
      'Your lead title will be visible as the title of the lead section in the widgets. For better UX consistency you should fill this field.',
  },
  NameLabel: {
    id: 'Lead.Form.InputType.Name.Label',
    defaultMessage: 'Name',
  },
  Message: {
    id: 'Lead.Form.InputType.Message',
    defaultMessage: 'Message',
  },
  MessageDesc: {
    id: 'Lead.Form.InputType.MessageDesc',
    defaultMessage:
      'This will be the message field appears in lead section before input fields and image. ',
  },
  LeadImage: {
    id: 'Lead.Form.InputType.Image',
    defaultMessage: 'Lead Image',
  },
  LeadImageDesc: {
    id: 'Lead.Form.InputType.ImageDesc',
    defaultMessage: 'Optional image to use in the lead section',
  },
  ButtonText: {
    id: 'Lead.Form.InputType.ButtonText',
    defaultMessage: 'Button Text',
  },
  ButtonTextDesc: {
    id: 'Lead.Form.InputType.ButtonTextDesc',
    defaultMessage:
      'If you want to customize the button text in the lead section.',
  },
  ButtonTextPlaceholder: {
    id: 'Lead.Form.InputType.ButtonTextPlaceholder',
    defaultMessage: 'Default Text is "Submit"',
  },
  UserInput: {
    id: 'Lead.Form.InputType.UserInput',
    defaultMessage: 'User Input',
  },
  PromotionCodes: {
    id: 'Lead.Form.InputType.PromotionCodes',
    defaultMessage: 'Promotion Codes',
  },
  CheckBoxInsGSM: {
    id: 'Lead.Form.InputType.CheckBoxInsGSM',
    defaultMessage: 'GSM',
  },
  CheckBoxInsEmail: {
    id: 'Lead.Form.InputType.CheckBoxInsEmail',
    defaultMessage: 'E-Mail',
  },
  CheckBoxInsName: {
    id: 'Lead.Form.InputType.CheckBoxInsName',
    defaultMessage: 'Name',
  },
  CheckBoxInsComment: {
    id: 'Lead.Form.InputType.CheckBoxInsComment',
    defaultMessage: 'Comment',
  },
  CheckBoxInsFile: {
    id: 'Lead.Form.InputType.CheckBoxInsFile',
    defaultMessage: 'File',
  },
  RequireTC1: {
    id: 'Lead.Form.InputType.RequireTC1',
    defaultMessage: 'Show Terms and Conditions',
  },
  RequireTC2: {
    id: 'Lead.Form.InputType.RequireTC2',
    defaultMessage: 'Show Second Terms and Conditions',
  },
  TCPlaceholder: {
    id: 'Lead.Form.InputType.TCPlaceholder',
    defaultMessage: 'Supports Markdown : www.markdownguide.org',
  },
  Mandatory: {
    id: 'Lead.Form.InputType.Mandatory',
    defaultMessage: 'Mandatory',
  },
  MandatoryText: {
    id: 'Lead.Form.InputType.MandatoryText',
    defaultMessage:
      'User will not be able to pass to the next question without an input',
  },
  StopSet: {
    id: 'Lead.Form.InputType.StopSet',
    defaultMessage: 'Stop Set',
  },
  StopSetText: {
    id: 'Lead.Form.InputType.StopSetText',
    defaultMessage: 'Stops the set after the input. Only works for Sets.',
  },
  RedirectLink: {
    id: 'Lead.Form.InputType.RedirectLink',
    defaultMessage: 'Redirect Link',
  },
  RedirectLinkURL: {
    id: 'Lead.Form.InputType.RedirectLinkURL',
    defaultMessage: '#next to trigger next question with button click',
  },
  RedirectLinkDescription: {
    id: 'Lead.Form.InputType.RedirectLinkURLDescription',
    defaultMessage: 'Can be used for internal redirect',
  },
  RedirectLinkExternalDescription: {
    id: 'Lead.Form.InputType.RedirectLinkExternalURLDescription',
    defaultMessage: 'Can be used for external redirect',
  },
  RedirectLinkIOSPlaceholder: {
    id: 'Lead.Form.InputType.RedirectLinkIOSPlaceholder',
    defaultMessage: 'iOS Link',
  },
  RedirectLinkAndroidPlaceholder: {
    id: 'Lead.Form.InputType.RedirectLinkAndroidPlaceholder',
    defaultMessage: 'Android Link',
  },
  ShortenLink: {
    id: 'Lead.Form.InputType.ShortenLink',
    defaultMessage: 'Don`t Use Short Link Service',
  },
  ShortenLinkDesc: {
    id: 'Lead.Form.InputType.ShortenLinkDesc',
    defaultMessage:
      'By default, we use our short link service to make links appear shorter and track the clicks. If you do not want to use this service or you want to use your own, you can turn off this by checking this checkbox. We can not track links if you disable our short link service. ',
  },
  ContentID: {
    id: 'Lead.Form.InputType.ContentID',
    defaultMessage: 'Content ID',
  },
  ContentIDPlaceholder: {
    id: 'Lead.Form.InputType.ContentIDPlaceholder',
    defaultMessage: 'Insert #next to trigger next questions',
  },
  YouTubeID: {
    id: 'Lead.Form.InputType.YouTubeID',
    defaultMessage: 'YouTube ID',
  },
  YouTubeIDDesc: {
    id: 'Lead.FormInputType.YouTubeIDDesc',
    defaultMessage:
      'If you want to display a youtube video embed inside of the lead section, you can enter the url or the id of the youtube video.',
  },
  YouTubeIDPlaceholder: {
    id: 'Lead.Form.InputType.YouTubeIDPlaceholder',
    defaultMessage: '-',
  },
  SubmitCancel: {
    id: 'Lead.Form.Submit.Cancel',
    defaultMessage: 'Cancel',
  },
  SubmitSave: {
    id: 'Lead.Form.Submit.Save',
    defaultMessage: 'Save',
  },
  Optional: {
    id: 'Lead.Form.InputType.Optional',
    defaultMessage: 'Optional',
  },
  OptionalDesc: {
    id: 'Lead.Form.InputType.OptionalDesc',
    defaultMessage:
      'Check this if you want people to submit lead with out accepting terms & conditions',
  },
  IncomingLeads: {
    id: 'Lead.IncomingLeads',
    defaultMessage: 'Incoming Leads',
  },
  CouponCodes: {
    id: 'Lead.CouponCodes',
    defaultMessage: 'Coupon Codes',
  },
  CouponCodesDesc: {
    id: 'Lead.CouponCodesLead',
    defaultMessage:
      'You can type in a single coupon code to display for all users or add multiple codes to display uniquely per user.',
  },
  Logs: {
    id: 'Lead.Logs',
    defaultMessage: 'Logs',
  },
  Edit: {
    id: 'Lead.Edit',
    defaultMessage: 'Edit',
  },
  Delete: {
    id: 'Lead.Delete',
    defaultMessage: 'Delete',
  },
  Id: {
    id: 'Lead.Id',
    defaultMessage: 'Id',
  },
  Code: {
    id: 'Lead.Code',
    defaultMessage: 'Code',
  },
  CreatedAt: {
    id: 'Lead.CreatedAt',
    defaultMessage: 'Created At',
  },
  UpdatedAt: {
    id: 'Lead.UpdatedAt',
    defaultMessage: 'Updated At',
  },
  IsUsed: {
    id: 'Lead.IsUsed',
    defaultMessage: 'Is Used',
  },
  SingleUse: {
    id: 'Lead.SingleUse',
    defaultMessage: 'Single Use',
  },
  UserId: {
    id: 'Lead.UserId',
    defaultMessage: 'Session Id',
  },
  LeadDate: {
    id: 'Lead.LeadDate',
    defaultMessage: 'Used At',
  },
  Time: {
    id: 'Lead.Time',
    defaultMessage: 'Time',
  },
  LeadId: {
    id: 'Lead.LeadId',
    defaultMessage: 'Lead Id',
  },
  ContentType: {
    id: 'Lead.ContentType',
    defaultMessage: 'Content Type',
  },
  ContentId: {
    id: 'Lead.ContentId',
    defaultMessage: 'Content Id',
  },
  Name: {
    id: 'Lead.Name',
    defaultMessage: 'Name',
  },
  NameDesc: {
    id: 'Lead.NameDesc',
    defaultMessage:
      'Name is used for internal tracking. This field will be only visible in Poltio apps and API. Your users will not see this field.',
  },
  Email: {
    id: 'Lead.Email',
    defaultMessage: 'Email',
  },
  Comment: {
    id: 'Lead.Comment',
    defaultMessage: 'Comment',
  },
  Phone: {
    id: 'Lead.Phone',
    defaultMessage: 'Phone',
  },
  PlayTime: {
    id: 'Lead.PlayTime',
    defaultMessage: 'Play Time (ms)',
  },
  CorrectAnswer: {
    id: 'Lead.CorrectAnswer',
    defaultMessage: 'Correct Answer',
  },
  Close: {
    id: 'Lead.Close',
    defaultMessage: 'Close',
  },
  Download: {
    id: 'Lead.Download',
    defaultMessage: 'Download',
  },
  NotifyEmails: {
    id: 'Lead.Form.NotifyEmails',
    defaultMessage: 'Notify Emails',
  },
  NotifyEmailsDescription: {
    id: 'Lead.Form.NotifyEmailsDescription',
    defaultMessage:
      'You can enter email addresses here to get notifications after someone completes a lead form. You can enter multiple emails by separating them with a comma (eg: john@doe.com,jane@doe.com)',
  },
  ExternalLink: {
    id: 'Lead.Form.LeadFormBodyDropDown.ExternalLink',
    defaultMessage: 'External Link',
  },
  NextQuestion: {
    id: 'Lead.Form.LeadFormBodyDropDown.NextQuestion',
    defaultMessage: 'Next Question',
  },
  Link: {
    id: 'Lead.Form.LeadFormBodyDropDown.Link',
    defaultMessage: 'Link',
  },

  ToContent: {
    id: 'Lead.Form.LeadFormBodyDropDown.ToContent',
    defaultMessage: 'ToContent',
  },
  True: {
    id: 'Lead.True',
    defaultMessage: 'True',
  },
  False: {
    id: 'Lead.False',
    defaultMessage: 'False',
  },
  Used: {
    id: 'Lead.Used',
    defaultMessage: 'Used',
  },
  NotUsed: {
    id: 'Lead.NotUsed',
    defaultMessage: 'Not Used',
  },
  MultiUse: {
    id: 'Lead.MultiUse',
    defaultMessage: 'Multi Use',
  },
  AddCouponCode: {
    id: 'Lead.AddCouponCode',
    defaultMessage: 'Add Coupon Code',
  },
  StopSetRedirect: {
    id: 'Lead.StopSetRedirect',
    defaultMessage: 'Stops the set',
  },
  LeadDesc1: {
    id: 'Lead.LeadDesc1',
    defaultMessage:
      'Leads can be triggered with a specific answer, content start or finish.',
  },
  LeadDesc2: {
    id: 'Lead.LeadDesc2',
    defaultMessage: 'You can add coupon codes to leads.',
  },
  LeadDesc3: {
    id: 'Lead.LeadDesc3',
    defaultMessage: 'You can insert a Youtube videos to leads.',
  },
  LeadDesc4: {
    id: 'Lead.LeadDesc4',
    defaultMessage:
      'Leads can be optional (user can close the lead screen and continue) or mandatory.',
  },
  LeadDesc5: {
    id: 'Lead.LeadDesc5',
    defaultMessage: 'Leads can be used to stop the content interaction.',
  },
  Example: {
    id: 'Lead.Form.LeadBodyDropDown.Exapmle',
    defaultMessage: 'For Example',
  },
  Automatically: {
    id: 'Lead.Form.LeadBodyDropDown.Automatically',
    defaultMessage: 'Open automatically',
  },
  AutomaticallyDesc: {
    id: 'Lead.Form.LeadBodyDropDown.AutomaticallyDesc',
    defaultMessage: 'Link will be opened automatically',
  },
  AutoDelay: {
    id: 'Lead.Form.LeadBodyDropDown.AutoDelay',
    defaultMessage: 'Automatically Open Delay',
  },
  AutoDelayDesc: {
    id: 'Lead.Form.LeadBodyDropDown.AutoDelayDesc',
    defaultMessage: 'Delay for automatically redirect in ms',
  },
  CustomizeAndroid: {
    id: 'Lead.Form.LeadBodyDropDown.CustomizeAndroid',
    defaultMessage: 'Customize Link for Android',
  },
  CustomizeIOS: {
    id: 'Lead.Form.LeadBodyDropDown.CustomizeIOS',
    defaultMessage: 'Customize Link for iOS',
  },
  DiffirentForMobile: {
    id: 'Lead.Form.LeadBodyDropDown.DiffrentForMobile',
    defaultMessage: 'Use different link for mobile',
  },
  AreuSureAll: {
    id: 'Lead.AreuSureAll',
    defaultMessage: 'Are you sure you want to delete ALL ?',
  },
  AreuSureSingle: {
    id: 'Lead.AreuSureSingle',
    defaultMessage: 'Are you sure you want to delete this coupon code ?',
  },
  SuccessDeleteAll: {
    id: 'Lead.SuccessDeleteAll',
    defaultMessage: 'All coupon codes deleted successfully',
  },
  SuccessDeleteSingle: {
    id: 'Lead.SuccessDeleteSingle',
    defaultMessage: 'Coupon code deleted successfully',
  },
  AddSuccess: {
    id: 'Lead.AddSuccess',
    defaultMessage: 'Coupon code added successfully',
  },
  EditSuccess: {
    id: 'Lead.EditSuccess',
    defaultMessage: 'Coupon code edited successfully',
  },
  RemoveAll: {
    id: 'Lead.RemoveAll',
    defaultMessage: 'Delete ALL coupon codes',
  },
  UpdateCode: {
    id: 'Lead.UpdateCode',
    defaultMessage: 'Edit Coupon Code',
  },
  RemoveCode: {
    id: 'Lead.RemoveCode',
    defaultMessage: 'Delete coupon code',
  },
  CalculatorScore: {
    id: 'Lead.CalculatorScore',
    defaultMessage: 'Calculator Score',
  },
  SelfDesc: {
    id: 'Lead.SelfDesc',
    defaultMessage: 'The current browsing context.',
  },
  BlankDesc: {
    id: 'Lead.BlankDesc',
    defaultMessage:
      'Usually a new tab, but users can configure browsers to open a new window instead.',
  },
  ParentDesc: {
    id: 'Lead.ParentDesc',
    defaultMessage:
      'The parent browsing context of the current one. If no parent, behaves as _self.',
  },
  TopDesc: {
    id: 'Lead.TopDesc',
    defaultMessage: `The topmost browsing context (the "highest" context that's an ancestor of the current one). If no ancestors, behaves as _self.`,
  },
  LinkTarget: {
    id: 'Lead.LinkTarget',
    defaultMessage: 'Link Target',
  },
  SingleUseDesc: {
    id: 'Lead.SingleUseDesc',
    defaultMessage:
      'Check this if you want only one user to use each coupon code.',
  },
  EveryLine: {
    id: 'Lead.EveryLine',
    defaultMessage: 'Every line will be a seperate coupon code.',
  },
  RemoveExisting: {
    id: 'Lead.RemoveExisting',
    defaultMessage: 'Remove Existing Coupon Codes',
  },
  RemoveExistingDesc: {
    id: 'Lead.RemoveExistingDesc',
    defaultMessage: 'Check this if you want to delete old coupon codes.',
  },
  EditCoupon: {
    id: 'Lead.EditCoupon',
    defaultMessage: 'Edit Coupon Code',
  },
  RedirectTo: {
    id: 'Lead.RedirectTo',
    defaultMessage: 'Users will be redirected to:',
  },
  UseLinks: {
    id: 'Lead.UseLink',
    defaultMessage: 'How to use links:',
  },
  UseBold: {
    id: 'Lead.UseBold',
    defaultMessage: 'How to use bold:',
  },
  SelectLeadType: {
    id: 'Lead.SelectLeadType',
    defaultMessage: 'Please select lead type',
  },
  DontShow: {
    id: 'Lead.DontShow',
    defaultMessage: "Don't show this again",
  },
});

export default messages;
