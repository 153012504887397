import React from 'react';
import { MagnifyingGlassIcon } from '@heroicons/react/24/solid';
import { injectIntl } from 'react-intl';
import { defineMessages } from 'react-intl.macro';

const messages = defineMessages({
  Search: {
    id: 'Button.Search',
    defaultMessage: 'Search',
  },
});

function classNames(...classes) {
  return classes.filter(Boolean).join(' ');
}

let SearchButton = ({ onClick, intl: { formatMessage }, notRounded }) => {
  return (
    <button
      type="button"
      className={classNames(
        notRounded ? 'rounded-r-none' : '',
        'poltio-btn poltio-search-btn rounded-l-none'
      )}
      onClick={onClick}
    >
      <MagnifyingGlassIcon
        className="h-5 w-5 text-gray-400"
        aria-hidden="true"
      />
      <span>{formatMessage(messages.Search)}</span>
    </button>
  );
};
SearchButton = injectIntl(SearchButton);

export { SearchButton, SearchButton as default };
