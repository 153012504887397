import React from 'react';
import { injectIntl } from 'react-intl';
import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import messages from './messages';
import ContentCardOptions from '../Common/ContentCard/ContentCardOptions';
import { Link } from 'react-router-dom';

import { feedAvatarPathRetina } from '../../core/constants';
dayjs.extend(relativeTime);

const ContentTable = ({
  contents,
  intl: { formatNumber, formatMessage },
  fetchContents,
}) => {
  return (
    <div className="flex flex-col py-4">
      <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
        <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
          <div className="overflow-hidden border-b border-gray-200 shadow sm:rounded-lg">
            <table className="min-w-full divide-y divide-gray-200">
              <thead className="bg-gray-50">
                <tr>
                  <th
                    scope="col"
                    className="hidden px-6 py-3 text-xs font-medium tracking-wider text-left text-gray-500 uppercase lg:flex"
                  >
                    {formatMessage(messages.Title)}
                  </th>
                  <th
                    scope="col"
                    className="px-6 py-3 text-xs font-medium tracking-wider text-left text-gray-500 uppercase"
                  >
                    {formatMessage(messages.Stats)}
                  </th>
                  <th
                    scope="col"
                    className="hidden px-6 py-3 text-xs font-medium tracking-wider text-left text-gray-500 uppercase lg:flex"
                  >
                    {formatMessage(messages.Dates)}
                  </th>
                  <th scope="col" className="relative px-6 py-3"></th>
                </tr>
              </thead>
              <tbody>
                {contents.map((c, i) => (
                  <tr
                    key={i}
                    className={i % 2 === 0 ? 'bg-gray-100' : 'bg-gray-50'}
                  >
                    <td className="hidden px-6 py-4 whitespace-nowrap lg:inline-flex">
                      <div className="flex items-center">
                        <div className="flex-shrink-0 w-16 h-16">
                          <Link
                            to={`/preview/${c.public_id}`}
                            style={{ cursor: 'pointer' }}
                          >
                            <img
                              className="w-16 h-16 rounded-md"
                              src={`${feedAvatarPathRetina}/${
                                c.background || 'placeholder/question/1.png'
                              }`}
                              alt=""
                            />
                          </Link>
                        </div>
                        <div className="ml-4">
                          <Link
                            to={`/c/${c.public_id}`}
                            style={{ cursor: 'pointer' }}
                          >
                            <div className="text-sm font-medium text-gray-900">
                              {c.title?.length > 30
                                ? `${c.title?.substring(0, 30)}...`
                                : c.title}
                            </div>
                            <div className="text-sm text-gray-500">
                              {c.name?.length > 30
                                ? `${c.name?.substring(0, 30)}...`
                                : c.name}
                            </div>
                          </Link>
                        </div>
                      </div>
                    </td>
                    <td className="px-6 py-4 text-sm text-gray-500 whitespace-nowrap">
                      <div className="text-sm font-medium text-gray-900">
                        {formatMessage(messages.Vote)}:{' '}
                        {formatNumber(c.vote_count)}
                      </div>
                      <div className="text-sm text-gray-500">
                        {formatMessage(messages.Voter)}:{' '}
                        {formatNumber(c.voter_count)}
                      </div>
                    </td>
                    <td className="hidden px-6 py-4 text-sm text-gray-500 whitespace-nowrap lg:inline-block">
                      <div className="text-sm font-medium text-gray-900">
                        {formatMessage(messages.Created)}:{' '}
                        {dayjs().to(dayjs(c.created_at)).toString()}
                      </div>
                      <div className="text-sm text-gray-500">
                        {formatMessage(messages.LastUpdated)}:{' '}
                        {dayjs().to(dayjs(c.updated_at)).toString()}
                      </div>
                      {c.end_date ? (
                        <div className="text-sm text-gray-500">
                          {formatMessage(messages.EndDate)}:{' '}
                          {dayjs().to(dayjs(c.end_date)).toString()}
                        </div>
                      ) : null}
                    </td>
                    <td className="px-6 py-4 text-sm font-medium text-right">
                      <ContentCardOptions
                        content={c}
                        fetchContents={fetchContents}
                      />
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
};

export default injectIntl(ContentTable);
