import { widgetLeadImagePath } from '../../../core/constants';
import ImageInput from '../../Common/ImageInput';
import { TextInput, Label, SwitchInput } from '../../Common/Form';
import LeadFormBodyDropDown from './LeadFormBodyDropDown';
import messages from '../messages';
import LeadFormBodyCouponCode from './LeadFormBodyCouponCode';

const LeadFormBodyRedirect = ({
  data,
  setData,
  formatMessage,
  code,
  setCode,
  panelOpen,
}) => {
  return (
    <div className="space-y-6 pt-6 pb-5">
      <div className="">
        <TextInput
          name="name"
          font="semibold"
          text={formatMessage(messages.NameLabel)}
          value={data.name || ''}
          onChange={(event) =>
            setData((prev) => ({ ...prev, name: event.target.value }))
          }
        />
        <p className="mt-2 text-sm text-gray-500">
          {formatMessage(messages.NameDesc)}
        </p>
      </div>
      <div className="">
        <TextInput
          name="name"
          font="semibold"
          text={formatMessage(messages.Title)}
          value={data.title || ''}
          onChange={(event) =>
            setData((prev) => ({ ...prev, title: event.target.value }))
          }
        />
        <p className="mt-2 text-sm text-gray-500">
          {formatMessage(messages.TitleDesc)}
        </p>
      </div>

      <div className="">
        <TextInput
          name="msg"
          font="semibold"
          text={formatMessage(messages.Message)}
          value={data.msg || ''}
          onChange={(event) =>
            setData((prev) => ({ ...prev, msg: event.target.value }))
          }
        />
        <p className="mt-2 text-sm text-gray-500">
          {formatMessage(messages.MessageDesc)}
        </p>
      </div>

      <div className="">
        <Label
          htmlFor="lead-image"
          text={formatMessage(messages.LeadImage)}
          font="semibold"
        />
        <div className="mt-1 h-56">
          <ImageInput
            title={formatMessage(messages.LeadImage)}
            src={data.image ? `${widgetLeadImagePath}/${data.image}` : ''}
            callback={(url) => setData((prev) => ({ ...prev, image: url }))}
            modal
          />
        </div>
        <p className="mt-2 text-sm text-gray-500">
          {formatMessage(messages.LeadImageDesc)}
        </p>
      </div>

      <div className="">
        <TextInput
          name="button_value"
          font="semibold"
          text={formatMessage(messages.ButtonText)}
          placeholder={formatMessage(messages.ButtonTextPlaceholder)}
          value={data.button_value || ''}
          onChange={(event) =>
            setData((prev) => ({
              ...prev,
              button_value: event.target.value,
            }))
          }
        />
        <p className="mt-2 text-sm text-gray-500">
          {formatMessage(messages.ButtonTextDesc)}
        </p>
      </div>

      <div className="">
        <Label
          htmlFor="lead-image"
          font="semibold"
          text={formatMessage(messages.RedirectLink)}
        />
        <div className="space-y-2.5">
          <LeadFormBodyDropDown data={data} setData={setData} />
        </div>
      </div>
      <div className="border-t pt-2 font-semibold">
        <SwitchInput
          name="stop_set"
          font="semibold"
          value={!!data.stop_set}
          onSwitch={(value) =>
            setData((prev) => ({
              ...prev,
              stop_set: value ? 1 : 0,
            }))
          }
        >
          {formatMessage(messages.StopSet)}
        </SwitchInput>
        <p className="mt-2 text-sm text-gray-500 font-normal">
          {formatMessage(messages.StopSetRedirect)}
        </p>
      </div>
      <LeadFormBodyCouponCode
        data={data}
        formatMessage={formatMessage}
        code={code}
        setCode={setCode}
        panelOpen={panelOpen}
      />
    </div>
  );
};

export default LeadFormBodyRedirect;
