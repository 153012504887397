import { Fragment, useState, useMemo, useEffect } from 'react';
import { injectIntl } from 'react-intl';
import { Listbox, Transition } from '@headlessui/react';
import { TextInput, Label, SwitchInput } from '../../Common/Form';
import ContentSearch from '../../Common/Form/ContentSearch';

import { CheckIcon, ArrowsUpDownIcon } from '@heroicons/react/24/solid';

import messages from '../messages';

function classNames(...classes) {
  return classes.filter(Boolean).join(' ');
}

const LeadFormBodyDropDown = ({ intl: { formatMessage }, data, setData }) => {
  const types = useMemo(
    () => [
      { id: 1, name: formatMessage(messages.Link) },
      { id: 2, name: formatMessage(messages.NextQuestion) },
      { id: 3, name: formatMessage(messages.ToContent) },
    ],
    [formatMessage]
  );
  const targetTypes = useMemo(
    () => [
      {
        id: 1,
        name: 'Blank',
        value: 'blank',
        desc: formatMessage(messages.BlankDesc),
      },
      {
        id: 2,
        name: 'Parent',
        value: 'parent',
        desc: formatMessage(messages.ParentDesc),
      },
      {
        id: 3,
        name: 'Self',
        value: 'self',
        desc: formatMessage(messages.SelfDesc),
      },
      {
        id: 4,
        name: 'Top',
        value: 'top',
        desc: formatMessage(messages.TopDesc),
      },
    ],
    [formatMessage]
  );
  const [selected, setSelected] = useState(types[0]);
  const [selectedTarget, setSelectedTarget] = useState(
    targetTypes.filter((t) => t.value === data.link_target)[0] || targetTypes[0]
  );
  const [openMobile, setOpenMobile] = useState(false);
  const [openIOS, setOpenIOS] = useState(false);
  const [openAndroid, setOpenAndroid] = useState(false);

  const selectedC = data?.redirect_url?.split(':');
  const c_type = selectedC ? selectedC[0] : null;
  const c_id = selectedC ? parseInt(selectedC[1]) : null;
  const { content_type, content_id } = {};
  const contentTypes = useMemo(() => [], []);
  const selectedContent = useMemo(
    () => ({
      type: c_type ? c_type : content_type,
      id: c_id ? c_id : content_id,
    }),
    [content_type, content_id, c_id, c_type]
  );

  useEffect(() => {
    if (data.redirect_url === '#next') {
      setSelected(types[1]);
    }
  }, [data.redirect_url, types]);

  useEffect(() => {
    selected.name === formatMessage(messages.NextQuestion)
      ? setData((prev) => ({
          ...prev,
          redirect_url: '#next',
        }))
      : setData((prev) => ({
          ...prev,
          redirect_url: data.redirect_url,
        }));
  }, [selected, formatMessage, setData, data.redirect_url]);

  useEffect(() => {
    setData((prev) => ({
      ...prev,
      link_target: selectedTarget.value,
    }));
  }, [selectedTarget, setData, selectedTarget.value]);

  return (
    <div>
      <Listbox value={selected} onChange={setSelected}>
        {({ open }) => (
          <>
            <div className="relative mt-1">
              <Listbox.Button className="relative w-full py-2 pl-3 pr-10 text-left bg-white border border-gray-300 cursor-default rounded-md shadow-sm focus:outline-none focus:ring-1 focus:ring-poltio-blue-500 focus:border-poltio-blue-500 sm:text-sm">
                <span className="block truncate">{selected.name}</span>
                <span className="absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none">
                  <ArrowsUpDownIcon
                    className="w-5 h-5 text-gray-400"
                    aria-hidden="true"
                  />
                </span>
              </Listbox.Button>

              <Transition
                show={open}
                as={Fragment}
                leave="transition ease-in duration-100"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
              >
                <Listbox.Options className="absolute z-10 w-full py-1 mt-1 overflow-auto text-base bg-white shadow-lg max-h-60 rounded-md ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                  {types.map((t) => (
                    <Listbox.Option
                      key={t.id}
                      className={({ active }) =>
                        classNames(
                          active
                            ? 'text-white bg-poltio-blue-600'
                            : 'text-gray-900',
                          'cursor-default select-none relative py-2 pl-3 pr-9'
                        )
                      }
                      value={t}
                    >
                      {({ selected, active }) => (
                        <>
                          <span
                            className={classNames(
                              selected ? 'font-semibold' : 'font-normal',
                              'block truncate'
                            )}
                          >
                            {t.name}
                          </span>

                          {selected ? (
                            <span
                              className={classNames(
                                active ? 'text-white' : 'text-indigo-600',
                                'absolute inset-y-0 right-0 flex items-center pr-4'
                              )}
                            >
                              <CheckIcon
                                className="w-5 h-5"
                                aria-hidden="true"
                              />
                            </span>
                          ) : null}
                        </>
                      )}
                    </Listbox.Option>
                  ))}
                </Listbox.Options>
              </Transition>
            </div>
          </>
        )}
      </Listbox>
      {selected.name === formatMessage(messages.Link) ||
      selected.name === formatMessage(messages.ToContent) ? (
        <>
          {selected.name === formatMessage(messages.ToContent) ? (
            <div className="space-y-2.5">
              <p className="mt-2 text-sm text-gray-500">
                {formatMessage(messages.RedirectLinkDescription)}{' '}
              </p>
              <ContentSearch
                onSelect={(item) =>
                  setData((prev) => ({
                    ...prev,
                    redirect_url: `https://www.poltio.com/widget/${item.public_id}`,
                  }))
                }
                contentTypes={contentTypes}
                selectedContent={selectedContent}
              />
            </div>
          ) : null}

          <div className="space-y-2.5">
            <p className="mt-2 text-sm text-gray-500">
              {formatMessage(messages.RedirectTo)}
            </p>
            <TextInput
              name="redirect_url"
              font="semibold"
              placeholder={formatMessage(messages.ExternalLink)}
              value={data.redirect_url !== null ? data.redirect_url : ''}
              onChange={(event) =>
                setData((prev) => ({
                  ...prev,
                  redirect_url: event.target.value,
                  type: 'redirect',
                }))
              }
            />
            <p className="p-2 mt-2 text-xs text-gray-300 bg-gray-600 rounded-lg bg-opacity-80">
              {formatMessage(messages.Example)}:
              <span className="font-bold text-green-400 ">
                {'  https://poltio.com/'}
              </span>
            </p>
          </div>

          <div className="pt-6 font-semibold">
            <SwitchInput
              name="auto_open"
              font="semibold"
              value={!!data.auto_open}
              onSwitch={(value) =>
                setData((prev) => ({
                  ...prev,
                  auto_open: value ? 1 : 0,
                }))
              }
            >
              {formatMessage(messages.Automatically)}
            </SwitchInput>
            <p className="mt-2 text-sm text-gray-500 font-normal">
              {formatMessage(messages.AutomaticallyDesc)}
            </p>
          </div>
          {data.auto_open ? (
            <div className="pt-6">
              <TextInput
                name="auto_open_delay"
                type="number"
                font="semibold"
                min={0}
                text={formatMessage(messages.AutoDelay)}
                value={data.auto_open_delay || 2500}
                step={500}
                onChange={(event) =>
                  setData((prev) => ({
                    ...prev,
                    auto_open_delay: event.target.valueAsNumber,
                  }))
                }
              />
              <p className="mt-2 text-sm text-gray-500">
                {formatMessage(messages.AutoDelayDesc)}
              </p>
            </div>
          ) : null}
          <div className="pt-2">
            <Label font="semibold" text={formatMessage(messages.LinkTarget)} />
            <Listbox value={selectedTarget} onChange={setSelectedTarget}>
              {({ open }) => (
                <>
                  <div className="relative mt-1">
                    <Listbox.Button className="relative w-full py-2 pl-3 pr-10 text-left bg-white border border-gray-300 cursor-default rounded-md shadow-sm focus:outline-none focus:ring-1 focus:ring-poltio-blue-500 focus:border-poltio-blue-500 sm:text-sm">
                      <span className="block truncate">
                        {selectedTarget.name}
                      </span>
                      <span className="absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none">
                        <ArrowsUpDownIcon
                          className="w-5 h-5 text-gray-400"
                          aria-hidden="true"
                        />
                      </span>
                    </Listbox.Button>

                    <Transition
                      show={open}
                      as={Fragment}
                      leave="transition ease-in duration-100"
                      leaveFrom="opacity-100"
                      leaveTo="opacity-0"
                    >
                      <Listbox.Options className="absolute z-10 w-full py-1 mt-1 overflow-auto text-base bg-white shadow-lg max-h-60 rounded-md ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                        {targetTypes.map((t) => (
                          <Listbox.Option
                            key={t.id}
                            className={({ active }) =>
                              classNames(
                                active
                                  ? 'text-white bg-poltio-blue-600'
                                  : 'text-gray-900',
                                'cursor-default select-none relative py-2 pl-3 pr-9'
                              )
                            }
                            value={t}
                          >
                            {({ selected, active }) => (
                              <>
                                <span
                                  className={classNames(
                                    selected ? 'font-semibold' : 'font-normal',
                                    'block truncate'
                                  )}
                                >
                                  {t.name}
                                </span>

                                {selected ? (
                                  <span
                                    className={classNames(
                                      active ? 'text-white' : 'text-indigo-600',
                                      'absolute inset-y-0 right-0 flex items-center pr-4'
                                    )}
                                  >
                                    <CheckIcon
                                      className="w-5 h-5"
                                      aria-hidden="true"
                                    />
                                  </span>
                                ) : null}
                              </>
                            )}
                          </Listbox.Option>
                        ))}
                      </Listbox.Options>
                    </Transition>
                    <div className="mt-2">
                      <span className="text-sm text-gray-500">
                        {selectedTarget.desc}
                      </span>
                    </div>
                  </div>
                </>
              )}
            </Listbox>
          </div>

          <div className="pt-6 font-semibold">
            <SwitchInput
              name="shorten_link"
              value={!!data.dont_shorten}
              onSwitch={(value) =>
                setData((prev) => ({
                  ...prev,
                  dont_shorten: value ? 1 : 0,
                }))
              }
            >
              {formatMessage(messages.ShortenLink)}
            </SwitchInput>
            <p className="mt-2 text-sm text-gray-500 font-normal">
              {formatMessage(messages.ShortenLinkDesc)}
            </p>
          </div>
          <div className="pt-2 font-semibold">
            <SwitchInput
              name={'mobile-open'}
              onSwitch={(value) => setOpenMobile(value)}
              value={openMobile}
            >
              {formatMessage(messages.DiffirentForMobile)}
            </SwitchInput>
          </div>
          {openMobile && (
            <div className="pl-2">
              <div className="pt-2">
                <SwitchInput
                  name={'ios-link'}
                  onSwitch={(value) => setOpenIOS(value)}
                  value={openIOS}
                >
                  {formatMessage(messages.CustomizeIOS)}
                </SwitchInput>
                <div className="pt-1">
                  {openIOS && (
                    <div>
                      <TextInput
                        name="ios_link"
                        placeholder={formatMessage(
                          messages.RedirectLinkIOSPlaceholder
                        )}
                        value={data.ios_link || ''}
                        onChange={(event) =>
                          setData((prev) => ({
                            ...prev,
                            redirect_url: event.target.value,
                          }))
                        }
                      />
                    </div>
                  )}
                </div>
              </div>
              <div className="pt-2">
                <SwitchInput
                  name={'android-link'}
                  onSwitch={(value) => setOpenAndroid(value)}
                  value={openAndroid}
                >
                  {formatMessage(messages.CustomizeAndroid)}
                </SwitchInput>
                <div className="pt-1">
                  {openAndroid && (
                    <div>
                      <TextInput
                        name="android_link"
                        placeholder={formatMessage(
                          messages.RedirectLinkAndroidPlaceholder
                        )}
                        value={data.android_link || ''}
                        onChange={(event) =>
                          setData((prev) => ({
                            ...prev,
                            redirect_url: event.target.value,
                          }))
                        }
                      />
                    </div>
                  )}
                </div>
              </div>
            </div>
          )}

          <div className="pt-5">
            <TextInput
              name="youtube_id"
              text={formatMessage(messages.YouTubeID)}
              font="semibold"
              // placeholder={formatMessage(messages.YouTubeIDPlaceholder)}
              value={data.youtube_id || ''}
              onChange={(event) =>
                setData((prev) => ({
                  ...prev,
                  youtube_id: event.target.value,
                }))
              }
            />
            <p className="mt-2 text-sm text-gray-500">
              {formatMessage(messages.YouTubeIDDesc)}
            </p>
          </div>
        </>
      ) : null}
    </div>
  );
};

export default injectIntl(LeadFormBodyDropDown);
