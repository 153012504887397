import { injectIntl } from 'react-intl';

import AccountSettings from './Account';
import SecuritySettings from './Security';
import SubscriptionInfo from './SubscriptionInfo';
import Loading from '../Common/Loading';
import LogoutSessions from './LogoutSessions';
import Heading from '../Common/Page/Heading';
import messages from './messages';

const Settings = ({ user, intl: { formatMessage } }) => {
  return (
    <>
      <main>
        {user?.id ? (
          <div className="container max-w-7xl mx-auto px-4 sm:px-6 md:px-8 divide-y divide-gray-300">
            <Heading title={formatMessage(messages.Settings)} />
            <AccountSettings user={user} />
            <SecuritySettings />
            <SubscriptionInfo user={user} />
            <LogoutSessions />
          </div>
        ) : (
          <Loading />
        )}
      </main>
    </>
  );
};

export default injectIntl(Settings);
