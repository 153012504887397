import React from 'react';
import { FormattedNumber } from 'react-intl';
import TextInput from '../Form/TextInput';
import Tooltip from '../Button/Tooltip';

const CardStat = ({
  children,
  label,
  value,
  isFormattedNumber,
  color,
  tinput,
  type = 'p',
  href = '',
  onClick,
  tooltipText = '',
}) => {
  const out = React.createElement(
    type,
    {
      ...(type === 'a' && {
        href: `${href}`,
        target: '_blank',
        rel: 'noreferrer',
      }),
      className: 'text-gray-900',
    },
    !tinput ? (
      <span className="inline-flex">
        {isFormattedNumber ? <FormattedNumber value={value} /> : value}
      </span>
    ) : (
      <div className="group cursor-pointer relative">
        <TextInput value={value} onClick={onClick}></TextInput>
        <Tooltip title={tooltipText} />
      </div>
    )
  );

  return (
    <div className="flex justify-between py-2 text-sm font-medium">
      <dt className="flex items-center text-gray-500 space-x-2">
        {children}
        <span className={`text-sm font-medium ${color}`}>{label}</span>
      </dt>
      {out}
    </div>
  );
};

export default CardStat;
