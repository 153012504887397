import { useState, useEffect, useCallback, useContext } from 'react';
import { useNavigate } from 'react-router-dom';

import { injectIntl } from 'react-intl';
import { getDataSources, deleteDataSource } from '../../api';

import Loading from '../Common/Loading';
import Pagination from '../Common/Pagination';
import { NotificationsCTX } from '../../contexts/Notification';
import DataSourceTable from './DataSourceTable';
import Button from '../Common/Button';
import messages from './messages';
import Heading from '../Common/Page/Heading';
import { BigButton } from '../Common/Button/Big';
import { FolderPlusIcon } from '@heroicons/react/24/solid';

const DataSources = ({ intl }) => {
  const [dataSources, setDataSources] = useState([]);
  const [loading, setLoading] = useState(true);
  const [pageData, setPageData] = useState({
    totalSize: 100,
    page: 1,
    sizePerPage: 50,
    lastPage: null,
  });
  const { success, error } = useContext(NotificationsCTX);

  const navigate = useNavigate();

  const fetchDataSources = useCallback(async () => {
    setLoading(true);
    const response = await getDataSources(pageData.page, pageData.sizePerPage);
    setDataSources(response.data);
    setLoading(false);
    setPageData((p) => {
      return {
        page: p.page,
        sizePerPage: p.sizePerPage,
        totalSize: response.data.total,
        lastPage: response.data.last_page,
      };
    });
  }, [pageData.page, pageData.sizePerPage]);

  const handlePageChange = (page, sizePerPage) => {
    setPageData({
      ...pageData,
      page,
      sizePerPage,
    });
  };

  useEffect(() => {
    fetchDataSources();
  }, [fetchDataSources]);

  const handleDeleteClick = async (id) => {
    if (window.confirm('Sure Delete?')) {
      try {
        await deleteDataSource(id);
        success('DataSource Deleted.');
        fetchDataSources();
      } catch (e) {
        error('Error');
      }
    }
  };

  return (
    <>
      <div>
        <div className="max-w-7xl mx-auto px-2 sm:px-6 md:px-8">
          <Heading
            title={intl.formatMessage(messages.DataSources)}
            body={intl.formatMessage(messages.DataSourcesDesc)}
          >
            <Button.Primary
              className="self-end"
              onClick={() => {
                navigate(`/data-source/new`);
              }}
            >
              {intl.formatMessage(messages.CreateNew)}
            </Button.Primary>
          </Heading>
        </div>

        {loading ? (
          <div className="max-w-7xl mx-auto px-2">
            <Loading />
          </div>
        ) : (
          <>
            <div className="max-w-7xl mx-auto px-2 sm:px-6 md:px-8 py-4">
              <div className="max-w-xl">
                <BigButton onClick={() => navigate('/data-source/new')}>
                  <FolderPlusIcon className="mx-auto h-12 w-12 text-white" />
                  Add A New DataSource
                </BigButton>
              </div>

              <DataSourceTable
                dataSources={dataSources}
                handleDeleteClick={handleDeleteClick}
                intl={intl}
              />
            </div>
            <div className="max-w-7xl mx-auto px-2 sm:px-6 md:px-8">
              <Pagination
                last={pageData.lastPage}
                active={pageData.page}
                size={pageData.sizePerPage}
                handlePageChange={handlePageChange}
              />
            </div>
          </>
        )}
      </div>
    </>
  );
};

export default injectIntl(DataSources);
