/* eslint-disable react/prop-types */
import React, { useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl.macro';
import { injectIntl } from 'react-intl';
import { isValidUrl } from '../../core/helper';
import messages from './messages';
import { webhooksSave } from '../../api';
import SlideOver from '../Common/SlideOver';
import TextInput from '../Common/Form/TextInput';
import TextAreaInput from '../Common/Form/TextAreaInput';
import Label from '../Common/Form/Label';
import ContentSearch from '../Common/Form/ContentSearch';

const sampleCode = `{
  "poltio_user_id": 1234,
  "session_id": "Poltio Session ID",
  "session_start_date": "2024-01-23T11:59:33.000000Z",
  "session_end_date": "2024-01-23T11:59:59.000000Z",
  "publisher_uuid": "Publisher UUID",
  "custom_id": "Custom Publisher ID",
  "event_time": 1568624875,
  "content_type": "Content type",
  "content_id": 12345,
  "complete_session": true,
  "answers": [
      {
          "question_id": 1234,
          "question": "Question Title",
          "answers": [
              {
                  "answer_id": 1234,
                  "answer": "Voted answer title"
              }
          ]
      }
  ],
  "result": {
      "result_id": 1234,
      "result_title": "Result Title",
      "result_image": "content/gcp/1678957893.2458.png",
      "result_desc": "Result Description",
      "time_spent": 16789,
      "right_answer": 0,
      "calculator_result": "0"
  },
  "leads": [
    {
       "user_id": 1234,
       "phone": "+1231231234",
       "email": "example@example.com",
       "name": "Name",
       "comment": "Comment",
       "created_at": "2024-01-23T11:59:43.000000Z",
       "tc_checked": true,
       "content_type" : "Content type",
       "content_id": 12345
    }
]
}`;

const defaultData = {
  name: '',
  delay: 10,
  url: '',
  send_answers: 1,
  send_leads: 1,
  incomplete_send: 0,
  incomplete_delay: 0,
};

const WebhookForm = ({
  open,
  setOpen,
  hook,
  success,
  error,
  handleSubmit,
  intl: { formatMessage },
  selectedContent,
}) => {
  const [data, setData] = useState(defaultData);
  const [saving, setSaving] = useState(false);
  const [id, setId] = useState('');

  useEffect(() => {
    if (selectedContent) {
      setId(selectedContent);
    }
    if (hook.id) {
      setData(hook);
      setId(hook.public_id);
    } else {
      setData(defaultData);
    }
  }, [open, hook, selectedContent]);

  useEffect(() => {
    setData((d) => {
      return {
        ...d,
        incomplete_delay: data.incomplete_send
          ? Math.max(d.incomplete_delay, d.delay, 60)
          : 0,
      };
    });
  }, [data.incomplete_send]);

  const onFormSubmit = async (e) => {
    e.preventDefault();
    if (formValid() && !saving) {
      try {
        setSaving(true);
        await webhooksSave({
          ...data,
          public_id: id,
          hook_id: hook.id,
        });
        success(formatMessage(messages.WebhookSaved));
        handleSubmit();
        setSaving(false);
        setOpen(false);
      } catch (e) {
        setSaving(false);
        error(formatMessage(messages.ErrorSaving));
      }
    }
  };

  const formValid = () => {
    const { incomplete_send, incomplete_delay, delay, name } = data;
    if (id?.length < 1) {
      error(formatMessage(messages.ErrorContentEmpty));
      return false;
    }
    if (name?.length < 1) {
      error(formatMessage(messages.ErrorNameEmpty));
      return false;
    }
    if (!isValidUrl(data.url)) {
      error(formatMessage(messages.EnterValidUrl));
      return false;
    }
    if (incomplete_send) {
      if (
        parseInt(incomplete_delay, 10) < parseInt(delay, 10) ||
        parseInt(incomplete_delay, 10) < 60
      ) {
        error(formatMessage(messages.ErrorIncompleteDelay));
        return false;
      }
    }
    return true;
  };

  const onSelect = (item) => {
    setId(item.public_id);
  };

  return (
    <SlideOver
      open={open}
      setOpen={setOpen}
      isLoading={saving}
      onSave={onFormSubmit}
      isDisabled={saving}
      onCancel={() => setOpen(false)}
    >
      <SlideOver.Header
        title={
          hook.id
            ? formatMessage(messages.EditWebhook)
            : formatMessage(messages.CreateNewWebHook)
        }
        subtitle={
          hook.id ? (
            <FormattedMessage
              id="Webhook.CanEdit"
              defaultMessage="You can edit and save your Webhook for {content_type} #{content_id}"
              values={{
                content_id: data.content_id,
                content_type: data.content_type,
              }}
            />
          ) : null
        }
        setOpen={setOpen}
      />

      <SlideOver.Body>
        <div className="px-4 divide-y divide-gray-200 sm:px-6">
          <div className="space-y-6 pt-6 pb-5">
            <div>
              <ContentSearch
                onSelect={onSelect}
                publicId={id || data.public_id}
              />
            </div>
            <div>
              <TextInput
                label={formatMessage(messages.Name)}
                type="text"
                name="name"
                value={data.name}
                onChange={(e) => setData({ ...data, name: e.target.value })}
              />
            </div>
            <div>
              <TextAreaInput
                autogrow
                label="Url"
                rows={2}
                name="url"
                value={data.url}
                onChange={(e) => setData({ ...data, url: e.target.value })}
              />
            </div>
            <div className="pt-2">
              <p className="mt-2 text-xs text-gray-400">
                {formatMessage(messages.WebhookDesc)}
              </p>
              <p className="p-2 mt-2 text-xs text-gray-300 bg-gray-600 bg-opacity-80 rounded-lg">
                https://yourapp.com/
                <span className="font-bold text-green-400 ">
                  {'webhook-endpoint'}
                </span>
              </p>
            </div>
            <div>
              <Label
                htmlFor="delay"
                font="medium"
                text={formatMessage(messages.Delay)}
              />
              <p className="mt-2 text-sm text-gray-500">
                {formatMessage(messages.DelayDesc)}
              </p>
              <TextInput
                type="number"
                name="delay"
                value={data.delay}
                onChange={(e) =>
                  setData({ ...data, delay: parseInt(e.target.value) })
                }
              />
            </div>

            <fieldset className="space-y-5">
              <div className="relative flex items-start">
                <div className="flex items-center h-5">
                  <input
                    id="send_answers"
                    aria-describedby="send_answers-description"
                    name="send_answers"
                    type="checkbox"
                    className="focus:ring-poltio-blue-500 h-4 w-4 text-poltio-blue-600 border-gray-300 rounded"
                    checked={data.send_answers}
                    onChange={(e) =>
                      setData({
                        ...data,
                        send_answers: e.target.checked ? 1 : 0,
                      })
                    }
                  />
                </div>
                <div className="ml-3 text-sm">
                  <label
                    htmlFor="send_answers"
                    className="font-medium text-gray-700"
                  >
                    {formatMessage(messages.SendAnswers)}
                  </label>
                  <p id="send-answers-description" className="text-gray-500">
                    {formatMessage(messages.SendAllData)}
                  </p>
                </div>
              </div>
              <div className="relative flex items-start">
                <div className="flex items-center h-5">
                  <input
                    id="send_leads"
                    aria-describedby="send_leads-description"
                    name="send_leads"
                    type="checkbox"
                    className="focus:ring-poltio-blue-500 h-4 w-4 text-poltio-blue-600 border-gray-300 rounded"
                    checked={data.send_leads}
                    onChange={(e) =>
                      setData({
                        ...data,
                        send_leads: e.target.checked ? 1 : 0,
                      })
                    }
                  />
                </div>
                <div className="ml-3 text-sm">
                  <label
                    htmlFor="send_leads"
                    className="font-medium text-gray-700"
                  >
                    {formatMessage(messages.SendLeads)}
                  </label>
                  <p id="send-leads-description" className="text-gray-500">
                    {formatMessage(messages.SendAllLeads)}
                  </p>
                </div>
              </div>
              <div className="relative flex items-start">
                <div className="flex items-center h-5">
                  <input
                    id="incomplete_send"
                    aria-describedby="incomplete_send-description"
                    name="incomplete_send"
                    type="checkbox"
                    className="focus:ring-poltio-blue-500 h-4 w-4 text-poltio-blue-600 border-gray-300 rounded"
                    checked={data.incomplete_send}
                    onChange={(e) =>
                      setData({
                        ...data,
                        incomplete_send: e.target.checked ? 1 : 0,
                      })
                    }
                  />
                </div>
                <div className="ml-3 text-sm">
                  <label
                    htmlFor="incomplete_send"
                    className="font-medium text-gray-700"
                  >
                    {formatMessage(messages.SendIncomplete)}
                  </label>
                  <p id="incomplete_send-description" className="text-gray-500">
                    {formatMessage(messages.Triggers)}
                  </p>
                </div>
              </div>
            </fieldset>
            {data.incomplete_send ? (
              <div>
                <label
                  htmlFor="incomplete_delay"
                  className="block text-sm font-medium text-gray-900"
                >
                  {formatMessage(messages.IncompleteDelay)}
                </label>
                <p className="mt-2 text-sm text-gray-500">
                  {formatMessage(messages.IncompleteDesc)}
                </p>
                <div className="mt-2">
                  <input
                    type="number"
                    name="incomplete_delay"
                    id="incomplete_delay"
                    className="block w-full shadow-sm sm:text-sm focus:ring-poltio-blue-500 focus:border-poltio-blue-500 border-gray-300 rounded-md"
                    value={data.incomplete_delay}
                    onChange={(e) =>
                      setData({
                        ...data,
                        incomplete_delay: parseInt(e.target.value),
                      })
                    }
                  />
                </div>
              </div>
            ) : null}
            <div className="mt-2">
              <p className="text-xs text-gray-400 mt-2">
                {formatMessage(messages.SendDesc)}
              </p>
              <div className="p-2 mt-2 text-xs text-gray-300 bg-gray-600 bg-opacity-80 rounded-lg">
                <div className="font-bold text-green-400 overflow-x-auto">
                  <p className="text-red-400">
                    {formatMessage(messages.To)}
                    {': '}
                    <span className="text-green-400">
                      yourapp.com/webhook-endpoint
                    </span>
                  </p>
                  <p className="text-red-400">
                    {formatMessage(messages.Method)}
                    {': '}
                    <span className="text-green-400">{'POST'}</span>
                  </p>
                  <p className="text-red-400">
                    {formatMessage(messages.Message)}
                    {': '}
                  </p>
                  <pre>
                    <code>
                      {JSON.stringify(JSON.parse(sampleCode), null, 2)}
                    </code>
                  </pre>
                </div>
              </div>
            </div>
            <div className="py-2 mt-10 max-w-md text-sm text-gray-600 font-semibold">
              {formatMessage(messages.MoreInfo)}{' '}
              <a
                className="text-poltio-blue after:content-['_↗'] hover:text-sky-400"
                href="https://platform.poltio.com/docs/webhooks/"
                target="_blank"
                rel="noreferrer"
              >
                {formatMessage(messages.Docs)}
              </a>
            </div>
          </div>
        </div>
      </SlideOver.Body>
      <SlideOver.Footer />
    </SlideOver>
  );
};

export default injectIntl(WebhookForm);
