import React, { useState, useEffect, useCallback, useContext } from 'react';

import { getPixelCodesPaginated } from '../../api';
import { pcDelete } from '../../api';

import { injectIntl } from 'react-intl';

import Loading from '../Common/Loading';
import Pagination from '../Common/Pagination';
import PixelCodeTable from './PixelCodesTable';
import { NotificationsCTX } from '../../contexts/Notification';
import SearchRow from './SearchRow';
import PixelCodesForm from './PixelCodesForm';
import messages from './messages';
import usePersistentStore from '../../core/usePersistentStore';
import Heading from '../Common/Page/Heading';

const PixelCodes = ({
  showUI = true,
  showCreateNewPixelCode = false,
  onCloseNewPixelCode = () => {},
  intl: { formatMessage },
}) => {
  const [codes, setCodes] = useState([]);
  const [loading, setLoading] = useState(true);
  const [sizePerPage, setSizePerPage] = usePersistentStore(50, 'spp-px');
  const [pageData, setPageData] = useState({
    totalSize: 100,
    page: 1,
    lastPage: null,
  });
  const [open, setOpen] = useState(false);
  const [activePc, setActivePc] = useState({});
  const { success, error } = useContext(NotificationsCTX);

  const fetchCodes = useCallback(async () => {
    setLoading(true);
    const response = await getPixelCodesPaginated(pageData.page, sizePerPage);
    setCodes(response.data.data);
    setLoading(false);
    setPageData((p) => {
      return {
        page: p.page,
        totalSize: response.data.total,
        lastPage: response.data.last_page,
      };
    });
  }, [pageData.page, sizePerPage]);

  const handlePageChange = (page, sizePerPage) => {
    setSizePerPage(sizePerPage);
    setPageData({ ...pageData, page });
  };

  const handleDeleteClick = async (id) => {
    if (window.confirm(formatMessage(messages.SureDelete))) {
      try {
        await pcDelete(id);
        success(formatMessage(messages.PixelCodeDeleted));
        fetchCodes();
      } catch (e) {
        error(formatMessage(messages.ErrorOccured));
      }
    }
  };

  useEffect(() => {
    fetchCodes();
  }, [fetchCodes]);

  const onEditClick = (h) => {
    setOpen(true);
    if (h) {
      setActivePc(h);
    }
  };

  const handleSubmit = () => {
    fetchCodes();
  };

  // -------------------- FOREIGN INTERACTIONS --------------------

  useEffect(() => {
    if (showCreateNewPixelCode) {
      onEditClick({});
    } else {
      setOpen(false);
    }
  }, [showCreateNewPixelCode]);

  const onNewPixelCodeSetOpen = (value) => {
    setOpen(value);
    onCloseNewPixelCode();
  };

  const onNewPixelCodeHandleSubmit = () => {
    handleSubmit();
    onCloseNewPixelCode();
  };

  // -------------------- RENDER --------------------

  return (
    <>
      {showUI ? (
        <div>
          <div className="max-w-7xl mx-auto px-2 sm:px-6 md:px-8">
            <Heading
              title={formatMessage(messages.PixelCodes)}
              body={formatMessage(messages.PixelCodesDesc)}
            >
              <SearchRow openNew={onEditClick} />
            </Heading>
          </div>
          <div className="max-w-7xl mx-auto px-2 sm:px-6 md:px-8">
            <Pagination
              last={pageData.lastPage}
              active={pageData.page}
              size={sizePerPage}
              handlePageChange={handlePageChange}
              totalSize={pageData.totalSize}
              showTotal
            />
          </div>
          {loading ? (
            <div className="max-w-7xl mx-auto px-2">
              <Loading />
            </div>
          ) : (
            <>
              <div className="max-w-7xl mx-auto px-2 sm:px-6 md:px-8">
                <PixelCodeTable
                  codes={codes}
                  openEdit={onEditClick}
                  handleDeleteClick={handleDeleteClick}
                />
              </div>
              <div className="max-w-7xl mx-auto px-2 sm:px-6 md:px-8">
                <Pagination
                  last={pageData.lastPage}
                  active={pageData.page}
                  size={sizePerPage}
                  handlePageChange={handlePageChange}
                />
              </div>
            </>
          )}
        </div>
      ) : null}
      <PixelCodesForm
        open={open}
        setOpen={onNewPixelCodeSetOpen}
        pc={activePc}
        success={success}
        error={error}
        handleSubmit={onNewPixelCodeHandleSubmit}
      />
    </>
  );
};

export default injectIntl(PixelCodes);
