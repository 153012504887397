import { defineMessages } from 'react-intl.macro';

export default defineMessages({
  SignIn: {
    id: 'Login.SignIn',
    defaultMessage: 'Sign in',
  },
  NotMember: {
    id: 'Login.NotMember',
    defaultMessage: 'Not a member?',
  },
  SignUp: {
    id: 'Login.SignUp',
    defaultMessage: 'Sign Up',
  },
  Email: {
    id: 'Login.Email',
    defaultMessage: 'Email address',
  },
  Pass: {
    id: 'Login.Pass',
    defaultMessage: 'Password',
  },
  PassAgain: {
    id: 'Login.PassAgain',
    defaultMessage: 'Password (again)',
  },
  Login: {
    id: 'Login.Login',
    defaultMessage: 'Login',
  },
  BackToLogin: {
    id: 'Login.BackToLogin',
    defaultMessage: 'Go Back to Login',
  },
  ResetPassword: {
    id: 'Login.ResetPassword',
    defaultMessage: 'Reset Password',
  },
  Forgot: {
    id: 'Login.Forgot',
    defaultMessage: 'Forgot your password?',
  },
  LoginText: {
    id: 'Login.LoginText',
    defaultMessage:
      'We at Poltio help brands access, engage with and learn from their users. Take your user engagement to the next level with Poltio!',
  },
  LearnMore: {
    id: 'Login.LearnMore',
    defaultMessage: 'Learn More',
  },
  About: {
    id: 'Login.About',
    defaultMessage: 'About',
  },
  Social: {
    id: 'Login.Social',
    defaultMessage: 'Social',
  },
  Trivia: {
    id: 'Login.Trivia',
    defaultMessage: 'Trivia',
  },
  AcceptTC: {
    id: 'Login.AcceptTC',
    defaultMessage: 'Accept the Terms and Conditions',
  },
  EnterValidEmail: {
    id: 'Login.EnterValidEmail',
    defaultMessage: 'Please enter a valid email address!',
  },
  WrongCombination: {
    id: 'Login.WrongCombination',
    defaultMessage: 'Wrong email/password combination!',
  },
  EmailSent: {
    id: 'Login.EmailSent',
    defaultMessage: 'An email has been sent to your email adress!',
  },
  CouldNotFindEmail: {
    id: 'Login.CouldNotFindEmail',
    defaultMessage: 'Could not find email address!',
  },
  EmailTaken: {
    id: 'Login.EmailTaken',
    defaultMessage: 'This email address has already been taken!',
  },
  PasswordSix: {
    id: 'Login.PasswordSix',
    defaultMessage: 'Password must be at least 8 characters!',
  },
  PasswordMatch: {
    id: 'Login.PasswordMatch',
    defaultMessage: 'Password fields must match!',
  },
  PleaseAgree: {
    id: 'Login.PleaseAgree',
    defaultMessage: 'Please agree to terms and conditions!',
  },
  VaError: {
    id: 'Login.VaError',
    defaultMessage:
      'Error occured while verifying your account. Please try again.',
  },
  VaSuccess: {
    id: 'Login.VaSuccess',
    defaultMessage:
      'Thank you! Your email has been verified. You can start enjoying Poltio, welcome aboard!',
  },
  RpError: {
    id: 'Login.RpError',
    defaultMessage:
      'Error occured while processing your request. Please try again.',
  },
  RpSuccess: {
    id: 'Login.RpSuccess',
    defaultMessage: 'Your password has been successfully reset!',
  },
  ReturnHome: {
    id: 'ReturnHome',
    defaultMessage: 'Return Home',
  },
  FirstName: {
    id: 'Login.FirstName',
    defaultMessage: 'First Name',
  },
  LastName: {
    id: 'Login.LastName',
    defaultMessage: 'Last Name',
  },
  AlreadyHave: {
    id: 'Login.AlreadyHave',
    defaultMessage: 'If you already have an account',
  },
  BackTo: {
    id: 'Login.BackTo',
    defaultMessage: 'Back to',
  },
  CreateNew: {
    id: 'Login.CreateNew',
    defaultMessage: 'Create New Account',
  },
  WelcomeBack: {
    id: 'Login.WelcomeBack',
    defaultMessage: 'Welcome Back!',
  },
  NewPass: {
    id: 'Login.NewPass',
    defaultMessage: 'Set a new password',
  },
  PasswordReset: {
    id: 'Login.PasswordReset',
    defaultMessage: 'Password Reset',
  },
  DontNeed: {
    id: 'Login.DontNeed',
    defaultMessage: "Or if you don't need to reset your password",
  },
  FirstNameError: {
    id: 'Login.FirstNameError',
    defaultMessage: 'First Name cannot be empty.',
  },
  LastNameError: {
    id: 'Login.LastNameError',
    defaultMessage: 'Last Name cannot be empty.',
  },
  StartTrial: {
    id: 'Login.StartTrial',
    defaultMessage: 'Or Start your 14 day trial today!',
  },
  RememberMe: {
    id: 'Login.RememberMe',
    defaultMessage: 'Remember Me',
  },
  ClickLogin1: {
    id: 'Login.ClickLogin1',
    defaultMessage: 'Click to log in',
  },
  ClickLogin2: {
    id: 'Login.ClickLogin2',
    defaultMessage:
      'with your email instead of entering your password and we will share the login link.',
  },
  WeHaveSent: {
    id: 'Login.WeHaveSent',
    defaultMessage: 'We have sent a login link to your email address',
  },
  InvalidEmail: {
    id: 'Login.InvalidEmail',
    defaultMessage: 'Invalid email adress!',
  },
  ErrorLink: {
    id: 'Login.Error',
    defaultMessage: 'Error while requesting link!',
  },
  TooMany: {
    id: 'Login.TooMany',
    defaultMessage:
      'Too many requests! Please try again later or sign in with your password.',
  },
  ErrorLogin: {
    id: 'Login.ErrorLogin',
    defaultMessage: 'Error while logging in!',
  },
});
