import { Fragment, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Dialog, Transition } from '@headlessui/react';

import { Bars4Icon, XMarkIcon } from '@heroicons/react/24/outline';

import messages from './messages';

import Avatar from './Avatar';
import { RoutesList } from '../Common/Routing';
import PoltioLogo from '../../images/PoltioLogo';

const NavMobile = ({ user, routes, formatMessage }) => {
  const [isOpen, setOpen] = useState(false);
  const navigate = useNavigate();

  const goToRoute = (e, path) => {
    e.preventDefault();
    if (path) {
      setOpen(false);
      navigate(path);
    }
  };

  return (
    <div className="lg:hidden">
      <Transition.Root show={isOpen} as={Fragment}>
        <Dialog
          as="div"
          static
          className="fixed inset-0 z-40 flex md:hidden"
          open={isOpen}
          onClose={setOpen}
        >
          <Transition.Child
            as={Fragment}
            enter="transition-opacity ease-linear duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="transition-opacity ease-linear duration-300"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 bg-gray-600 bg-opacity-75" />
          </Transition.Child>
          <Transition.Child
            as={Fragment}
            enter="transition ease-in-out duration-300 transform"
            enterFrom="-translate-x-full"
            enterTo="translate-x-0"
            leave="transition ease-in-out duration-300 transform"
            leaveFrom="translate-x-0"
            leaveTo="-translate-x-full"
          >
            <div className="relative flex flex-col flex-1 w-full max-w-xs pt-5 pb-4 bg-white">
              <Transition.Child
                as={Fragment}
                enter="ease-in-out duration-300"
                enterFrom="opacity-0"
                enterTo="opacity-100"
                leave="ease-in-out duration-300"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
              >
                <div className="absolute top-0 right-0 pt-2 -mr-12">
                  <button
                    className="flex items-center justify-center w-10 h-10 ml-1 rounded-full focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white"
                    onClick={() => setOpen(false)}
                  >
                    <span className="sr-only">Close sidebar</span>
                    <XMarkIcon
                      className="w-6 h-6 text-white"
                      aria-hidden="true"
                    />
                  </button>
                </div>
              </Transition.Child>
              <div className="flex items-center flex-shrink-0 ml-auto mr-auto">
                <PoltioLogo className="w-auto h-8" />
              </div>
              <div className="flex-1 h-0 mt-5 overflow-y-auto">
                <RoutesList
                  className={'mobile'}
                  goToRoute={goToRoute}
                  routes={routes}
                  formatMessage={formatMessage}
                  user={user}
                />
              </div>

              <Avatar
                user={user}
                goToRoute={goToRoute}
                messages={messages}
                formatMessage={formatMessage}
              />
            </div>
          </Transition.Child>
          <div className="flex-shrink-0 w-14" aria-hidden="true">
            {/* Dummy element to force sidebar to shrink to fit close icon */}
          </div>
        </Dialog>
      </Transition.Root>
      <div className="pt-1 pl-1  sm:pl-3 sm:pt-3">
        <button
          className="-ml-0.5 -mt-0.5 h-12 w-12 inline-flex items-center justify-center rounded-md text-gray-500 hover:text-gray-900 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-poltio-blue-500"
          onClick={() => setOpen(true)}
        >
          <span className="sr-only">Open sidebar</span>
          <Bars4Icon className="w-6 h-6" aria-hidden="true" />
        </button>
      </div>
    </div>
  );
};

export default NavMobile;
