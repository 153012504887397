import history from '../../core/history';
import { useNavigate } from 'react-router-dom';
import { injectIntl } from 'react-intl';
import messages from './messages';
import Button from '../Common/Button';
import logo from '../../images/poltio-logo.svg';

const _ErrorPageDetail = ({
  intl: { formatMessage },
  errorCode,
  error,
  message,
  upgrade,
}) => {
  const navigate = useNavigate();
  return (
    <div className=" min-h-full px-4 py-16 lg:px-8 sm:px-6 sm:py-24 flex flex-col">
      <main className="flex justify-center">
        <p className="text-4xl font-bold tracking-tight text-poltio-blue-500 sm:text-5xl self-center">
          {errorCode}
        </p>
        <div className="sm:ml-6">
          <img className="w-auto h-12 ml-6" src={logo} alt="Workflow" />
          <div className=" sm:border-gray-200 sm:pl-6 w-2/3 mt-6 sm:border-l ">
            <h1 className="text-4xl font-bold tracking-tight text-gray-900 sm:text-5xl">
              {error}
            </h1>
            <p className="mt-1 text-base text-gray-500">{message}</p>
            {upgrade && (
              <p className="mt-1 text-base text-gray-500">{upgrade}</p>
            )}
          </div>
          <div className="mt-10 flex space-x-3 sm:border-l sm:border-transparent sm:pl-6">
            <Button.Secondary onClick={() => navigate(-1)}>
              {formatMessage(messages.GoBack)}
            </Button.Secondary>
            <Button.Secondary href={`/`}>
              {formatMessage(messages.ReturnHome)}
            </Button.Secondary>
            <Button.Secondary href={`/login`}>{'Login'}</Button.Secondary>
          </div>
        </div>
      </main>
    </div>
  );
};

const ErrorPageDetail = injectIntl(_ErrorPageDetail);

const ErrorPage = ({ intl: { formatMessage }, user }) => {
  const search = history.location.search;
  const params = new URLSearchParams(search); // eslint-disable-line no-undef
  const error = params.get('error');

  const configs = {
    default: {
      message: formatMessage(messages.GenericErrorMessage),
      error: 'Server Error',
    },
    429: {
      message: !user?.is_verified
        ? formatMessage(messages.LimitErrorMessage)
        : formatMessage(messages.LimitErrorNVMessage),
      error: 'Limit Error',
      upgrade: formatMessage(messages.Upgrade),
    },
    401: {
      message: formatMessage(messages.ErrorMessage401),
      error: 'Unauthorized',
    },
  };

  const config = configs[error] || configs['default'];

  return (
    <ErrorPageDetail
      message={config.message}
      error={config.error}
      errorCode={error}
      upgrade={config?.upgrade}
    />
  );
};

export default injectIntl(ErrorPage);
