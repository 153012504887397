import React, { useContext } from 'react';
import { injectIntl } from 'react-intl';
import { ChevronRightIcon } from '@heroicons/react/24/outline';
import { PlusIcon } from '@heroicons/react/24/outline';

import { Expander } from '../../../../../Common/Form';
import { EditorCTX } from '../../../../context';
import { ConfTypes } from '../../../../enums';

import { ResultsList } from '../List';
import messages from '../../messages';

function classNames(...classes) {
  return classes.filter(Boolean).join(' ');
}

const _AddNewButton = ({ intl: { formatMessage } }) => {
  const { addNewResult } = useContext(EditorCTX);

  return (
    <div className="flex justify-center py-2 mt-4 ">
      <button
        className="px-2 poltio-btn poltio-btn-secondary"
        onClick={addNewResult}
        disabled={!addNewResult}
      >
        {formatMessage(messages.AddNewResult)}
        <PlusIcon className="h-6 ml-2" />
      </button>
    </div>
  );
};

const AddNewButton = injectIntl(_AddNewButton);

const ResultList = ({ intl: { formatMessage } }) => {
  const { focus, confType: activeType } = useContext(EditorCTX);
  return (
    <Expander
      as="div"
      open={activeType === ConfTypes.Result}
      className="overflow-hidden bg-white rounded-lg shadow divide-y divide-gray-200"
    >
      <Expander.Button
        onClick={() => focus(ConfTypes.Result, '')}
        className="flex w-full px-2 py-2 sm:px-2"
      >
        {({ open }) => (
          <div className="flex justify-between w-full mx-2 mt-1 mb-2">
            <h2 className="flex text-xl">{formatMessage(messages.Results)}</h2>
            <ChevronRightIcon
              className={classNames('flex w-5 mt-1', open ? 'rotate-90' : '')}
            />
          </div>
        )}
      </Expander.Button>
      <Expander.Panel>
        <div className="px-4 py-5 sm:p-6">
          <ResultsList />
          <AddNewButton />
        </div>
      </Expander.Panel>
    </Expander>
  );
};

export default injectIntl(ResultList);
