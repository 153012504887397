import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { ChevronRightIcon } from '@heroicons/react/24/outline';
import { PinAngle, Pin } from '../../images';

import messages from './messages';

import NavButton from '../Common/Button/NavButton';

import Avatar from './Avatar';
import { RoutesList } from '../Common/Routing';
import PoltioLogoSM from '../../images/PoltioLogoSM';
import PoltioLogo from '../../images/PoltioLogo';

function classNames(...classes) {
  return classes.filter(Boolean).join(' ');
}

const docs = [
  {
    name: 'Platform Docs',
    href: 'https://platform.poltio.com/docs/',
    initial: 'P',
  },
  {
    name: 'Blog',
    href: 'https://blog.poltio.com/',
    initial: 'B',
  },
];

const NavDesktop = ({ user, routes, formatMessage }) => {
  const [canCollapse, setCanCollapse] = useState(
    (localStorage.getItem('nav_bar_collapse') || 'closed') === 'open'
  );

  const navigate = useNavigate();

  const goToRoute = (e, href) => {
    e.preventDefault();
    if (href) navigate(href);
  };

  const toggleCanCollapse = () => {
    setCanCollapse((prev) => {
      localStorage.setItem('nav_bar_collapse', !prev ? 'open' : 'closed');

      return !prev;
    });
  };

  return (
    <div
      className={classNames(
        'relative h-full hidden lg:flex ',
        canCollapse ? 'w-14' : 'poltio-is-hovering'
      )}
    >
      <div
        className={classNames(
          canCollapse && 'absolute z-10',
          'h-full flex flex-shrink-0 flex-col bg-white group overflow-hidden overflow-y-auto'
        )}
      >
        <div className="px-2 mb-1 w-full border-b border-gray-200">
          <button
            className="mt-1 px-1 h-12 w-full flex flex-shrink-0 items-center rounded-md relative overflow-hidden"
            onClick={(event) => goToRoute(event, '/')}
          >
            <span className="sr-only">Open sidebar</span>
            <div className="absolute w-screen h-full py-2 justify-start items-start overflow-hidden">
              <PoltioLogo className="absolute transition-opacity duration-100 opacity-0 group-hover:opacity-100 poltio-is-hovering:opacity-100 h-8" />
              <PoltioLogoSM className="absolute transition-opacity duration-100 opacity-100 group-hover:opacity-0 poltio-is-hovering:opacity-0 h-8" />
            </div>
          </button>
        </div>

        <RoutesList
          className="flex-1 bg-white"
          // goToRoute={goToRoute}
          routes={routes}
          canCollapse={canCollapse}
          formatMessage={formatMessage}
          user={user}
        />
        <div className="-mx-2 sm:mx-2 space-y-1 grid">
          <div
            className={classNames(
              canCollapse ? 'lg:hidden lg:group-hover:block' : '',
              'sm:mx-2 text-xs font-semibold leading-6 text-gray-400 min-w-full'
            )}
          >
            {formatMessage(messages.Resources)}
          </div>
          {docs?.map((c, i) => (
            <a
              key={i}
              href={c.href}
              target="_blank"
              rel="noreferrer"
              className="btn group/a gap-x-3 rounded-md p-2 text-sm leading-6 font-medium  min-w-full relative flex items-center"
            >
              <span
                className="flex h-6 w-6 shrink-0 items-center justify-center rounded-lg border text-[0.625rem] font-medium group-hover/a:border-poltio-blue group-hover/a:text-poltio-blue"
                aria-hidden="true"
              >
                {c.initial}
              </span>
              <span className="truncate absolute text-gray-600 left-12 text-left w-48 after:content-['_↗']">
                {c.name}
              </span>
            </a>
          ))}
        </div>

        <div className="poltio-nav flex px-2 bg-white py-2">
          {canCollapse ? (
            <>
              <div className="flex group-hover:hidden w-full">
                <NavButton
                  item={{
                    name: 'Expand',
                    icon: ChevronRightIcon,
                  }}
                  onClick={toggleCanCollapse}
                  formatMessage={formatMessage}
                />
              </div>
              <div className="hidden group-hover:flex w-full">
                <NavButton
                  item={{
                    name: 'Pin',
                    icon: PinAngle,
                  }}
                  onClick={toggleCanCollapse}
                  formatMessage={formatMessage}
                />
              </div>
            </>
          ) : (
            <div className="flex w-full">
              <NavButton
                item={{
                  name: 'Unpin',
                  icon: Pin,
                }}
                onClick={toggleCanCollapse}
                formatMessage={formatMessage}
              />
            </div>
          )}
        </div>

        <div className="poltio-nav-pipe">
          <div className="" />
        </div>

        <Avatar
          user={user}
          goToRoute={goToRoute}
          messages={messages}
          formatMessage={formatMessage}
          ring
        />
      </div>
    </div>
  );
};

export default NavDesktop;
