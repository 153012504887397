import React, { FunctionComponent, ReactNode } from 'react';

type SectionProps = {
  title: string;
  body?: string;
  titleExtra?: string;
  children?: ReactNode;
  desc?: string;
};

const Heading: FunctionComponent<SectionProps> = ({
  title,
  titleExtra,
  body,
  children,
}) => (
  <div className="pb-5 border-b border-gray-200">
    <div className="w-full flex flex-row items-center ">
      <div className="mt-2 w-full flex">
        <h1 className="text-3xl font-semibold leading-6 text-gray-900 whitespace-nowrap">
          {title}
        </h1>
        <span className="text-sm font-normal mt-1.5 ml-10">{titleExtra}</span>
      </div>
      {children ? (
        <div className="hidden md:flex flex-col items-end self-start w-full">
          <div className="flex justify-start md:justify-end w-full">
            {children}
          </div>
        </div>
      ) : null}
    </div>
    <p className="mx-0.5 mt-2 max-w-7xl text-sm text-gray-500">{body}</p>
    {children ? (
      <div className="flex md:hidden flex-col items-end self-start w-full">
        <div className="flex flex-col justify-start md:justify-end w-full gap-y-4">
          {children}
        </div>
      </div>
    ) : null}
  </div>
);

export default Heading;
