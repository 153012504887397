import React from 'react';

function classNames(...classes) {
  return classes.filter(Boolean).join(' ');
}

export const MenuItem = ({ onClick, children, isFirst, className }) => {
  return (
    <button
      type="button"
      className={classNames(
        'poltio-menu-item',
        isFirst ? ' rounded-l-md' : '-ml-px',
        className
      )}
      onClick={onClick}
    >
      {!!children && children}
    </button>
  );
};

export const SubMenuItem = ({ onClick, children, className }) => {
  return (
    <button
      type="button"
      className={classNames('poltio-menu-item', 'w-full', className)}
      onClick={onClick}
    >
      {!!children && children}
    </button>
  );
};
