import React, { Fragment } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import { defineMessages } from 'react-intl.macro';
import { injectIntl } from 'react-intl';

import Loading from '../Loading';
import SlideOverHeader from './Header';
import Button from '../Button';

function classNames(...classes) {
  return classes.filter(Boolean).join(' ');
}

const messages = defineMessages({
  Cancel: {
    id: 'SlideOver.Cancel',
    defaultMessage: 'Cancel',
  },
  Save: {
    id: 'SlideOver.Save',
    defaultMessage: 'Save',
  },
});

let SlideOverFormControl = ({
  intl: { formatMessage },
  isSaving = false,
  onSave = undefined,
  onCancel = undefined,
  isDisabled = true,
}) => (
  <div className="flex-shrink-0 px-4 py-4 flex justify-end">
    {onCancel && (
      <Button.Secondary onClick={onCancel}>
        {formatMessage(messages.Cancel)}
      </Button.Secondary>
    )}
    {onSave && (
      <Button.Primary
        className="ml-4"
        onClick={onSave}
        disabled={isSaving || isDisabled}
      >
        {formatMessage(messages.Save)}
      </Button.Primary>
    )}
  </div>
);
SlideOverFormControl = injectIntl(SlideOverFormControl);

let SlideOverBody = (props) => {
  return (
    <>
      <div
        className={classNames(
          'flex flex-1 flex-col justify-between overflow-y-auto',
          props?.className
        )}
      >
        {props?.children}
      </div>
    </>
  );
};

let SlideOverFooter = (props) => {
  return (
    <>
      <SlideOverFormControl {...props} />
    </>
  );
};

let SlideOver = ({
  open,
  setOpen = () => {},
  onSave = undefined,
  onCancel = undefined,
  isDisabled = true,
  isLoading = false,
  children,
}) => {
  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog
        as="div"
        className="fixed inset-0 overflow-hidden"
        onClose={setOpen}
      >
        <div className="absolute inset-0 overflow-hidden">
          <Dialog.Overlay className="absolute inset-0 bg-black opacity-30" />

          {isLoading && (
            <div className="fixed inset-y-0 max-w-md right-0 flex w-screen justify-center bg-black bg-opacity-70 transition-opacity z-20">
              <div className="flex justify-center h-full">
                <Loading />
              </div>
            </div>
          )}

          <div className="fixed inset-y-0 pl-16 max-w-full right-0 flex">
            <Transition.Child
              as={Fragment}
              enter="transform transition ease-in-out duration-500 sm:duration-700"
              enterFrom="translate-x-full"
              enterTo="translate-x-0"
              leave="transform transition ease-in-out duration-500 sm:duration-700"
              leaveFrom="translate-x-0"
              leaveTo="translate-x-full"
            >
              <div className="w-screen max-w-md">
                <div className="h-full divide-y divide-gray-200 flex flex-col bg-white shadow-xl">
                  {React.Children.map(children, (child) => {
                    if (!React.isValidElement(child)) {
                      return child;
                    }
                    return React.cloneElement(child, {
                      isSaving: isLoading,
                      onSave: onSave,
                      onCancel: onCancel,
                      isDisabled: isDisabled,
                    });
                  })}
                </div>
              </div>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
};
SlideOver.Header = SlideOverHeader;
SlideOver.Body = SlideOverBody;
SlideOver.Footer = SlideOverFooter;

export default SlideOver;
