import React, { useContext } from 'react';
import { injectIntl } from 'react-intl';

import {
  SwitchInput,
  TextInput,
  LeadInput,
  PixelCodeInput,
} from '../../../../../Common/Form';
import { EditorCTX } from '../../../../context';
import AnswerResultRelation from './AnswerResultRelation';
import messages from '../../messages';
import { EditorState } from '../../../../enums';

const AnswerConf = ({ intl: { formatMessage } }) => {
  const {
    data,
    confField,
    activeQuestionIdx: qidx,
    set,
    showCreateNewLead,
    showCreateNewPixelCode,
    save,
    editorStatus,
  } = useContext(EditorCTX);

  // answer index is not stored directly, get it from FOCUS data
  const aidx = confField.split('.').slice(-1)[0];
  const answers = data?.questions?.[qidx]?.answers;
  const answer = answers?.[aidx];

  const toggleCorrectAnswer = (nextValue) => {
    if (nextValue) {
      // if answer is to be correct, unset previous correct if present
      // there can be only one correct answer at the moment
      const padix = answers?.findIndex((el) => el?.is_right_answer);
      if (padix >= 0) {
        set(`questions.${qidx}.answers.${padix}.is_right_answer`, 0);
      }
    }
    // set `this` answer's `is_right_answer` value
    set(`questions.${qidx}.answers.${aidx}.is_right_answer`, nextValue ? 1 : 0);
  };

  const saveAnswer = () => {
    if (editorStatus === EditorState.Dirty) {
      save();
    }
  };

  return (
    <>
      <div>
        <SwitchInput
          value={answer?.is_right_answer || 0}
          onSwitch={toggleCorrectAnswer}
          description={formatMessage(messages.CorrectAnswerDesc)}
        >
          {formatMessage(messages.CorrectAnswer)}
        </SwitchInput>
      </div>
      <div className="pt-1 pb-2">
        <TextInput
          key={confField}
          name="luv"
          label={formatMessage(messages.LeadUrlVar)}
          value={answer?.luv || ''}
          debounce={{ timeout: 1000 }}
          onChange={(value) => {
            set(`questions.${qidx}.answers.${aidx}.luv`, value);
          }}
          description={formatMessage(messages.LeadUrlVarDesc)}
        />
      </div>

      <div className="pt-1 pb-2">
        <TextInput
          key={`${confField}_calc`}
          name="luv"
          label={formatMessage(messages.CalculatorValue)}
          value={answer?.cal_val || ''}
          debounce={{ timeout: 1000 }}
          onChange={(value) => {
            set(`questions.${qidx}.answers.${aidx}.cal_val`, value);
          }}
          description={formatMessage(messages.CalculatorValueDesc)}
        />
      </div>

      <div className="pt-1" onClick={saveAnswer}>
        <PixelCodeInput
          pixelCode={answer?.pixel_code || ''}
          onSelect={(pixelCode) => {
            set(`questions.${qidx}.answers.${aidx}.pixel_code`, {
              id: pixelCode.id,
              name: pixelCode.name,
            });
          }}
          onDelete={() => {
            set(`questions.${qidx}.answers.${aidx}.pixel_code`, null);
            set(`questions.${qidx}.answers.${aidx}.pixel_code_id`, null);
          }}
          onNew={() => {
            showCreateNewPixelCode();
          }}
        />
      </div>
      <div className="pb-14" onClick={saveAnswer}>
        <LeadInput
          leadId={answer?.lead?.id || ''}
          onSelect={(lead) => {
            set(`questions.${qidx}.answers.${aidx}.lead.id`, lead.id);
          }}
          onDelete={() => {
            set(`questions.${qidx}.answers.${aidx}.lead.id`, null);
          }}
          onNew={() => {
            showCreateNewLead();
          }}
        />
      </div>
      <div className="">
        <AnswerResultRelation />
      </div>
    </>
  );
};

export default injectIntl(AnswerConf);
