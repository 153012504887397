import React, { useEffect, useMemo, useState } from 'react';
import { injectIntl } from 'react-intl';
import { useLocation, useNavigate } from 'react-router-dom';

import NavDesktop from './NavDesktop';
import NavMobile from './NavMobile';
import TcWarning from '../Common/Alert/tcwarning';
import Footer from '../Common/Footer';
import { getProfile } from '../../api';
import { getToken } from '../../core/helper';
import messages from './messages';

import routes from '../routes';
import { InfoAlert, SuccessAlert } from '../Common/Alert';

const navRoutes = routes.filter((route) => route.navbar ?? true);

const Navigation = ({ intl: { formatMessage }, children }) => {
  const [user, setUser] = useState({});
  const [showSubscribeWarning, setShowSubscribeWarning] = useState(false);
  const location = useLocation();
  const navigate = useNavigate();
  const params = new URLSearchParams(location.search);
  const subscribed = params.get('subscribed');

  const token = useMemo(() => getToken(), []);

  useEffect(() => {
    async function fetchData() {
      if (token) {
        const { data } = await getProfile();
        setUser(data);
      }
    }
    fetchData();
  }, [token]);

  useEffect(() => {
    const today = new Date();
    const createdDate = new Date(user.created_at);
    const checkedDate = new Date(
      createdDate.setMonth(createdDate.getMonth() + 1)
    );

    const surveyDone = !!window.localStorage?.getItem('onboarding_survey_done');

    if (!surveyDone && checkedDate > today) {
      if (window.poltio && window.poltio.processFloatingWidgets) {
        setTimeout(() => {
          window.poltio.processFloatingWidgets();
          window.poltio.toggleSlideOver();
        }, 500);
      }
    }
  }, [user.created_at]);

  useEffect(() => {
    const shouldShowSubscribe =
      user?.level === 14 &&
      subscribed !== 'true' &&
      !location.pathname.includes('/subscribe');

    setShowSubscribeWarning(shouldShowSubscribe);
  }, [user?.level, user?.is_verified, location.pathname, subscribed]);

  const handleMessages = (event) => {
    if (event.data?.gtmData?.action === 'result_view') {
      window.localStorage?.setItem('onboarding_survey_done', true);
    }
  };

  useEffect(() => {
    window.addEventListener('message', handleMessages);

    return () => {
      window.removeEventListener('message', handleMessages);
    };
  }, []);

  const childrenWithProps = React.Children.map(children, (child) => {
    if (React.isValidElement(child)) {
      return React.cloneElement(child, { user, token });
    }
    return child;
  });

  return (
    <div className="flex flex-col md:flex-row h-screen overflow-hidden bg-gray-100">
      {/* desktop first because of mobile menu button */}
      <NavDesktop
        user={user}
        routes={navRoutes}
        formatMessage={formatMessage}
      />
      <NavMobile user={user} routes={navRoutes} formatMessage={formatMessage} />

      <main className="relative z-0 flex-1 overflow-y-auto focus:outline-none">
        {!location.pathname.includes('/errorpage') && user?.level && (
          <TcWarning user={user} />
        )}
        <div className="max-w-7xl mx-auto px-4 sm:px-6 md:px-8 mb-4">
          {showSubscribeWarning ? (
            <InfoAlert
              title={'You are currently on trial'}
              body={'You can upgrade your account by selecting a tier'}
              footer={
                <button
                  className="bg-blue-50 px-2 py-1.5 rounded-md text-sm font-medium text-blue-800 hover:bg-blue-100 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-blue-50 focus:ring-blue-600"
                  onClick={() => {
                    setShowSubscribeWarning(false);
                    navigate('/subscribe');
                  }}
                >
                  {'Subscribe'}
                </button>
              }
            />
          ) : null}
          {subscribed === 'true' ? (
            <SuccessAlert title={'You have successfully subscribed!'} />
          ) : null}
        </div>
        <div className={'pt-6 h-full'}>
          {childrenWithProps}
          <Footer />
        </div>
        <div
          className="poltio-widget-floating"
          data-poltio-widget-content="3daa9a0d-ebd6-4450-8e72-c0cd9aec8007"
          data-poltio-widget-loc="en"
          data-poltio-widget-uuid={`${user.id}`}
          data-poltio-widget-disclaimer="off"
          data-poltio-widget-resultfit="vf"
          data-poltio-widget-openlinks="_parent"
          data-poltio-floating-bgcolor="rgba(0, 158, 235, 1)"
          data-poltio-floating-textcolor="white"
          data-poltio-floating-text={`${formatMessage(messages.ContentCreationAssistant)}`}
          data-poltio-floating-position="center-right"
          data-poltio-floating-hide="true"
          data-poltio-floating-zindex="99"
          data-poltio-slideover-position="right"
          data-poltio-slideover-bgcolor="rgb(239, 248, 255)"
          data-poltio-slideover-zindex="100"
          data-poltio-slideover-type="modal"
        ></div>
      </main>
    </div>
  );
};

export default injectIntl(Navigation);
