/* eslint-disable react/style-prop-object */
import DatePicker, { registerLocale } from 'react-datepicker';
import StatItem from '../StatItem';
import 'react-datepicker/dist/react-datepicker.css';
import { CalendarIcon } from '@heroicons/react/24/solid';
import { enUS, tr } from 'date-fns/locale';

registerLocale('en', enUS);
registerLocale('tr', tr);

function classNames(...classes) {
  return classes.filter(Boolean).join(' ');
}

const StatList = ({
  intl: { formatMessage },
  title,
  stats,
  messages,
  setDatesChanged,
  setStartDate,
  datesChanged,
  intl,
  startDate,
  setEndDate,
  endDate,
  statsPage,
}) => (
  <>
    <div className={classNames(statsPage ? 'flex lg:-mt-20' : ' grid md:flex')}>
      {title ? (
        <div className="self-end w-full">
          <h3 className="text-lg font-medium text-gray-600 leading-6">
            {title}
          </h3>
        </div>
      ) : null}
      <div className="mt-2 md:mt-1 md:flex gap-y-6 gap-x-4 max-w-7xl mx-auto justify-end w-full">
        <div>
          <label className="block text-sm font-medium leading-6 text-gray-900">
            {formatMessage(messages.StartDate)}
          </label>
          <div className="relative mt-2 rounded-md shadow-sm">
            <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
              <CalendarIcon
                className="h-5 w-5 text-gray-400 z-50"
                aria-hidden="true"
              />
            </div>
            <DatePicker
              className="block w-full rounded-md border-0 py-1.5 pl-10 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-poltio-blue-600 sm:text-sm sm:leading-6"
              locale={intl.locale.substring(0, 2)}
              formatDate="yyyy-mm-dd"
              selected={datesChanged ? startDate : null}
              onChange={(date) => {
                setStartDate(date);
                setDatesChanged(true);
              }}
            />
          </div>
        </div>

        <div>
          <label className="block text-sm font-medium leading-6 text-gray-900">
            {formatMessage(messages.EndDate)}
          </label>
          <div className="relative mt-2 rounded-md shadow-sm">
            <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
              <CalendarIcon
                className="h-5 w-5 text-gray-400 z-50"
                aria-hidden="true"
              />
            </div>
            <DatePicker
              className="block w-full rounded-md border-0 py-1.5 pl-10 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-poltio-blue-600 sm:text-sm sm:leading-6"
              locale={intl.locale.substring(0, 2)}
              formatDate="yyyy-mm-dd"
              selected={datesChanged ? endDate : null}
              onChange={(date) => {
                setEndDate(date);
                setDatesChanged(true);
              }}
            />
          </div>
        </div>
      </div>
    </div>
    <div className="pb-4 mt-4">
      <dl className="overflow-hidden bg-white rounded-lg shadow grid grid-cols-1 divide-y divide-gray-200 md:grid-cols-3 md:divide-y-0 md:divide-x">
        <StatItem
          item={{
            name: formatMessage(messages.TotalVote),
            stat: stats.vote,
            previousStat: null,
          }}
        />
        <StatItem
          item={{
            name: formatMessage(messages.UniqueVoter),
            stat: stats.voter,
            previousStat: null,
          }}
        />
        <StatItem
          item={{
            name: formatMessage(messages.ViewCount),
            stat: stats.view,
            previousStat: null,
          }}
        />
      </dl>
    </div>
    <div className="pb-4">
      <dl className="overflow-hidden bg-white rounded-lg shadow grid grid-cols-1 divide-y divide-gray-200 md:grid-cols-3 md:divide-y-0 md:divide-x">
        <StatItem
          item={{
            name: formatMessage(messages.Start),
            stat: stats.start,
            previousStat: null,
          }}
        />
        <StatItem
          item={{
            name: formatMessage(messages.Finish),
            stat: stats.finish,
            previousStat: null,
          }}
        />
        <StatItem
          item={{
            name: formatMessage(messages.CompletionRate),
            stat: (stats.finish / (stats.start || 1)) * 100,
            previousStat: null,
            isPercent: true,
            colors: true,
          }}
        />
      </dl>
    </div>
    <div className="pb-4">
      <dl className="overflow-hidden bg-white rounded-lg shadow grid grid-cols-1 divide-y divide-gray-200 md:grid-cols-3 md:divide-y-0 md:divide-x">
        <StatItem
          item={{
            name: formatMessage(messages.ResultView),
            stat: stats.result_view,
            previousStat: null,
          }}
        />
        <StatItem
          item={{
            name: formatMessage(messages.ResultClick),
            stat: stats.result_click,
            previousStat: null,
          }}
        />
        <StatItem
          item={{
            name: formatMessage(messages.UniqueResultClick),
            stat: (stats.result_click_unique / (stats.finish || 1)) * 100,
            previousStat: null,
            isPercent: true,
          }}
        />
      </dl>
    </div>
  </>
);

export default StatList;
