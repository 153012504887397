import { useRef, useContext, useState } from 'react';
import { injectIntl } from 'react-intl';
import { defineMessages } from 'react-intl.macro';

import {
  LinkIcon,
  PencilIcon,
  ChartBarSquareIcon,
  EllipsisVerticalIcon,
  CodeBracketIcon,
  ClipboardIcon,
  DocumentDuplicateIcon,
  TrashIcon,
  ShareIcon,
  DocumentChartBarIcon,
  CircleStackIcon,
} from '@heroicons/react/24/solid';
import { deleteContent, duplicateContent } from '../../../api';
import { embedUrlPrefix } from '../../../core/constants';
import { useNavigate } from 'react-router-dom';
import { NotificationsCTX } from '../../../contexts/Notification';
import { SubMenuItem, MenuItem as MainMenuItem } from '../Button/MenuButtons';
import { ItemsList } from '../Form/Dropdown';
import { Link } from 'react-router-dom';

const messages = defineMessages({
  Open: {
    id: 'ContentCard.Options.Open',
    defaultMessage: 'Open',
  },
  Edit: {
    id: 'ContentCard.Options.Edit',
    defaultMessage: 'Edit',
  },
  Stats: {
    id: 'ContentCard.Options.Stats',
    defaultMessage: 'Stats',
  },
  OpenOptions: {
    id: 'ContentCard.Options.OpenOptions',
    defaultMessage: 'OpenOptions',
  },
  CopyEmbedCode: {
    id: 'ContentCard.Options.CopyCode',
    defaultMessage: 'CopyCode',
  },
  CopyDirectLink: {
    id: 'ContentCard.Options.CopyDirectLink',
    defaultMessage: 'CopyDirectLink',
  },
  Duplicate: {
    id: 'ContentCard.Options.Duplicate',
    defaultMessage: 'Duplicate',
  },
  Delete: {
    id: 'ContentCard.Options.Delete',
    defaultMessage: 'Delete',
  },
  VotesReport: {
    id: 'ContentCard.VotesReport',
    defaultMessage: 'Votes Report',
  },
  AnswersReport: {
    id: 'ContentCard.AnswerReports',
    defaultMessage: 'Quiz Winners Report',
  },
  SetReport: {
    id: 'ContentCard.SetReport',
    defaultMessage: 'Set Report',
  },
  TestReport: {
    id: 'ContentCard.TestReport',
    defaultMessage: 'Test Report',
  },
  EmbedCodeGenerator: {
    id: 'ContentCard.CodeGenerator',
    defaultMessage: 'Code Generator',
  },
  UsersByAnswer: {
    id: 'ContentCard.UsersByAnswer',
    defaultMessage: 'Users By Answer',
  },
  WebHooks: {
    id: 'ContentCard.WebHooks',
    defaultMessage: 'Web Hooks',
  },
  SheetHooks: {
    id: 'ContentCard.SheetHooks',
    defaultMessage: 'Sheet Hooks',
  },
  SessionsReport: {
    id: 'ContentCard.SessionReport',
    defaultMessage: 'Session Report',
  },
  VotersReport: {
    id: 'ContentCard.VotersReport',
    defaultMessage: 'Voters Report',
  },
});

const ContentCardOptionsContent = ({
  open,
  setOpen,
  formatMessage,
  content,
  fetchContents,
}) => {
  const container = useRef(null);

  const navigate = useNavigate();
  const { error, success } = useContext(NotificationsCTX);

  const copyEmbedCode = async () => {
    try {
      await navigator.clipboard.writeText(
        `<iframe id="poltio-embed-${content.public_id}" class="poltio-embed" src="https://${embedUrlPrefix}/widget/${content.public_id}?share=on&notify=on&align=center&loc=en" width="100%" height="800" frameBorder="0" allowFullScreen="allowfullscreen" scrolling="yes" title="Embed" allow="clipboard-write"></iframe><script src="https://platform.poltio.com/widget.js" defer></script>`
      );
      success('Embed code copied.');
    } catch (e) {
      error('Error copying embed code!');
    }
  };

  const copyLink = async () => {
    try {
      await navigator.clipboard.writeText(
        `https://${embedUrlPrefix}/widget/${content.public_id}?align=center&share=off&notify=off`
      );
      success('Link copied.');
    } catch (e) {
      console.log(e);
      error('Error copying link!');
    }
  };

  const _deleteContent = async () => {
    if (window.confirm('Are you sure you want to delete this content!')) {
      try {
        await deleteContent(content.public_id);
        success('Content deleted');
        fetchContents();
      } catch (e) {
        error('Error occured!');
      }
    }
  };

  const _duplicateContent = async () => {
    try {
      await duplicateContent(content.public_id);
      success('Content duplicated. ');
      fetchContents();
    } catch (e) {
      error('Error occured!');
    }
  };

  const renderer = (item, index) =>
    item.anchor_tag ? (
      <Link
        to={item.anchor_link}
        state={item.anchor_state}
        key={`key-${index}`}
      >
        <SubMenuItem key={`key-${index}`} className={item?.className}>
          <item.icon className={item.iconStyle} aria-hidden="true" />
          <span className={item.textStyle || ''}>{item.text}</span>
        </SubMenuItem>
      </Link>
    ) : (
      <SubMenuItem
        key={`key-${index}`}
        onClick={() => {
          item?.onClick();
          setOpen(false);
        }}
        className={item?.className}
      >
        <item.icon className={item.iconStyle} aria-hidden="true" />
        <span className={item.textStyle || ''}>{item.text}</span>
      </SubMenuItem>
    );

  const data = [
    {
      anchor_tag: true,
      anchor_link: `/editor/${content.public_id}`,
      icon: PencilIcon,
      iconStyle: 'w-5 h-5 mr-2 -ml-1 text-gray-400',
      text: formatMessage(messages.Edit),
      className: 'xl:hidden',
    },
    {
      anchor_tag: true,
      anchor_link: `/c/${content.public_id}`,
      icon: ChartBarSquareIcon,
      iconStyle: 'w-5 h-5 mr-2 -ml-1 text-gray-400',
      text: formatMessage(messages.Stats),
      className: 'xl:hidden',
    },
    {
      onClick: copyEmbedCode,
      icon: CodeBracketIcon,
      iconStyle: 'w-5 h-5 mr-2 -ml-1 text-gray-400',
      text: formatMessage(messages.CopyEmbedCode),
    },
    {
      onClick: () =>
        navigate(`/c/${content.public_id}`, {
          state: {
            embedModal: true,
          },
        }),
      icon: CodeBracketIcon,
      iconStyle: 'w-5 h-5 mr-2 -ml-1 text-gray-400',
      text: formatMessage(messages.EmbedCodeGenerator),
    },
    {
      onClick: copyLink,
      icon: ClipboardIcon,
      iconStyle: 'w-5 h-5 mr-2 -ml-1 text-gray-400',
      text: formatMessage(messages.CopyDirectLink),
    },
    {
      onClick: _duplicateContent,
      icon: DocumentDuplicateIcon,
      iconStyle: 'w-5 h-5 mr-2 -ml-1 text-gray-500',
      text: formatMessage(messages.Duplicate),
    },
    {
      onClick: _deleteContent,
      icon: TrashIcon,
      iconStyle: 'w-5 h-5 mr-2 -ml-1 text-red-500',
      text: formatMessage(messages.Delete),
      textStyle: 'text-red-500',
    },
    {
      anchor_tag: true,
      anchor_link: `/reports`,
      anchor_state: {
        public_id: content.public_id,
        report: `content-sessions`,
      },
      icon: CircleStackIcon,
      iconStyle: 'w-5 h-5 mr-2 -ml-1 text-gray-500',
      text: formatMessage(messages.SessionsReport),
    },
    {
      anchor_tag: true,
      anchor_link: `/reports`,
      anchor_state: {
        public_id: content.public_id,
        report: `content-voters`,
      },
      icon: CircleStackIcon,
      iconStyle: 'w-5 h-5 mr-2 -ml-1 text-gray-500',
      text: formatMessage(messages.VotersReport),
    },
    {
      onClick: () =>
        navigate(`/c/${content.public_id}`, {
          state: { answerVotersModal: true },
        }),
      icon: CircleStackIcon,
      iconStyle: 'w-5 h-5 mr-2 -ml-1 text-gray-500',
      text: formatMessage(messages.UsersByAnswer),
    },
    {
      anchor_tag: true,
      anchor_link: `/webhooks/${content.public_id}`,
      icon: ShareIcon,
      iconStyle: 'w-5 h-5 mr-2 -ml-1 text-gray-500',
      text: formatMessage(messages.WebHooks),
    },
    {
      anchor_tag: true,
      anchor_link: `/sheethooks/${content.public_id}`,
      icon: DocumentChartBarIcon,
      iconStyle: 'w-5 h-5 mr-2 -ml-1 text-gray-500',
      text: formatMessage(messages.SheetHooks),
    },
  ];

  const onCloseCallback = () => {
    setOpen(false);
  };

  return (
    <div
      className="absolute right-0 top-1/2 z-10 w-56 -mr-1 shadow-lg origin-top-right focus:outline-none pointer-events-none"
      ref={container}
    >
      {open && (
        <div className="py-1">
          <ItemsList
            data={data}
            renderer={renderer}
            containerRef={container}
            onCloseCallback={onCloseCallback}
            modal={true}
          />
        </div>
      )}
    </div>
  );
};

const ContentCardOptions = ({
  content,
  intl: { formatMessage },
  fetchContents,
}) => {
  const [open, setOpen] = useState(false);

  return (
    <div className="z-0 w-full py-2">
      <span className="relative inline-flex divide-x-2 ">
        <Link
          to={`//${embedUrlPrefix}/widget/${content.public_id}`}
          target="_blank"
          rel="noreferrer"
        >
          <MainMenuItem isFirst={true}>
            <LinkIcon
              className="w-5 h-5 mr-2 -ml-1 text-gray-400"
              aria-hidden="true"
            />
            <div className="hidden sm:flex">{formatMessage(messages.Open)}</div>
          </MainMenuItem>
        </Link>

        <Link to={`/editor/${content.public_id}`}>
          <MainMenuItem className="hidden xl:flex">
            <PencilIcon
              className="w-5 h-5 mr-2 -ml-1 text-gray-400"
              aria-hidden="true"
            />
            {formatMessage(messages.Edit)}
          </MainMenuItem>
        </Link>

        <Link to={`/c/${content.public_id}`}>
          <MainMenuItem className="hidden xl:flex">
            <ChartBarSquareIcon
              className="w-5 h-5 mr-2 -ml-1 text-gray-400"
              aria-hidden="true"
            />
            {formatMessage(messages.Stats)}
          </MainMenuItem>
        </Link>

        <span className="relative block -ml-px">
          <button
            className="
            relative z-0 inline-flex items-center px-2 py-2 text-sm font-medium text-gray-500 bg-white rounded-r-lg
            hover:bg-gray-50
            focus:outline-none
          "
            onClick={() => setOpen((prev) => !prev)}
          >
            <span className="sr-only">
              {formatMessage(messages.OpenOptions)}
            </span>
            <EllipsisVerticalIcon className="w-5 h-5" aria-hidden="true" />
          </button>
          <ContentCardOptionsContent
            open={open}
            setOpen={setOpen}
            formatMessage={formatMessage}
            content={content}
            fetchContents={fetchContents}
          />
        </span>
      </span>
    </div>
  );
};

export default injectIntl(ContentCardOptions);
