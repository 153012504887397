import {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from 'react';
import type { Ref } from 'react';

import { Alert } from './CardError';
import { Success } from './CardSuccess';
import { Warning } from './CardWarning';
import { NotificationType } from './enums';

type NotificationEvent = {
  action: number;
  key: string;
};

type Notification = {
  key: string;
  title: string;
  message: string | object | (string | object)[];
  timeout: number;
  notification: any;
};

const EventTypeEnum = Object.freeze({
  clear: 0,
});

type TNotificationAdd = (
  type: NotificationType,
  title: string,
  message: string | object | (string | object)[],
  timeout: number
) => void;

export interface ContainerRef {
  add: TNotificationAdd;
}

type TContainerDef = (props: any, ref: Ref<ContainerRef>) => JSX.Element | null;

const ContainerDef: TContainerDef = (props, ref) => {
  const [notifications, setNotifications] = useState<Notification[]>([]);
  const [eventQueue, setEventQueue] = useState<NotificationEvent[]>([]);

  const idx = useRef(0);

  const clear = (key = '') => {
    setEventQueue([...eventQueue, { action: EventTypeEnum.clear, key: key }]);
  };

  useEffect(() => {
    if (eventQueue.length === 0) return;

    const event = eventQueue[0];

    if (event?.action === EventTypeEnum.clear) {
      setNotifications((prev) => {
        if (event?.key === '') {
          return [];
        }

        return prev.filter((value) => value.key !== event.key);
      });
      setEventQueue((prev) => {
        return prev.filter((value) => value.key !== event.key);
      });
    }
  }, [eventQueue]);

  const add: TNotificationAdd = (type, title, message, timeout = 2500) => {
    let key = idx.current++;
    let notification: Notification = {
      key: `notification-${key}`,
      title: title,
      message: message,
      timeout: timeout,
      notification: undefined,
    };

    switch (type) {
      case NotificationType.ALERT:
      case NotificationType.ERROR:
        notification.notification = Alert;
        break;

      case NotificationType.SUCCESS:
        notification.notification = Success;
        break;

      case NotificationType.WARNING:
        notification.notification = Warning;
        break;

      default:
        notification.notification = Alert;
        break;
    }

    setNotifications([...notifications, notification]);
  };

  useImperativeHandle(ref, () => ({
    add: add,
    clear: clear,
  }));

  return (
    <div
      className="poltio-notification-container lg:max-w-l"
      style={{ zIndex: '200' }}
    >
      <div className="rounded-lg overflow-hidden">
        {notifications?.map((entry) => {
          const Notification = entry.notification;

          return (
            <Notification
              key={entry.key}
              title={entry.title}
              message={entry.message}
              timeout={entry.timeout}
              close={() => clear(entry.key)}
            />
          );
        })}
      </div>
    </div>
  );
};
export const Container = forwardRef(ContainerDef);
