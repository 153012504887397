import StatList from '../Common/StatList';

export const Stats = ({
  intl: { formatMessage },
  messages,
  setDatesChanged,
  setStartDate,
  datesChanged,
  intl,
  startDate,
  dashboardStats,
  setEndDate,
  endDate,
}) => {
  return (
    <StatList
      title={formatMessage(messages.Highlights)}
      messages={messages}
      stats={dashboardStats}
      setDatesChanged={setDatesChanged}
      setStartDate={setStartDate}
      startDate={startDate}
      setEndDate={setEndDate}
      endDate={endDate}
      intl={intl}
      datesChanged={datesChanged}
    />
  );
};

export default Stats;
