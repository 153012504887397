import HealthButton from './HealthButton';
import SearchButton from './SearchButton';
import NavButton from './NavButton';
import { Link } from 'react-router-dom';

function classNames(...classes) {
  return classes.filter(Boolean).join(' ');
}

const Button = ({
  className = '',
  type = 'button',
  onClick = undefined,
  disabled = false,
  children,
  href,
  tooltip = false,
  message = '',
  showSpinner = false,
}) =>
  href ? (
    <Link
      type={type}
      className={`poltio-btn ${className}`}
      disabled={disabled}
      to={href}
    >
      {!!children && children}
    </Link>
  ) : (
    <>
      {tooltip ? (
        <div className="relative flex flex-col items-center group">
          <button
            type={type}
            className={`poltio-btn ${className}`}
            disabled={disabled}
            onClick={onClick}
          >
            {!!children && children}
          </button>
          <div className="absolute bottom-0 right-0 flex-col text-center hidden mb-6 group-hover:flex items-end">
            <span className="relative z-100 p-2 text-xs leading-none text-white whitespace-no-wrap bg-gray-300 shadow-lg rounded-md w-48">
              {message}
            </span>
            <div className="mr-1 w-3 h-3 -mt-2 rotate-45 bg-gray-300"></div>
          </div>
        </div>
      ) : (
        <button
          type={type}
          className={`poltio-btn ${className}`}
          disabled={disabled}
          onClick={onClick}
        >
          {showSpinner ? (
            <svg
              width="20"
              height="20"
              fill="currentColor"
              className="mr-2 animate-spin"
              viewBox="0 0 1792 1792"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path d="M526 1394q0 53-37.5 90.5t-90.5 37.5q-52 0-90-38t-38-90q0-53 37.5-90.5t90.5-37.5 90.5 37.5 37.5 90.5zm498 206q0 53-37.5 90.5t-90.5 37.5-90.5-37.5-37.5-90.5 37.5-90.5 90.5-37.5 90.5 37.5 37.5 90.5zm-704-704q0 53-37.5 90.5t-90.5 37.5-90.5-37.5-37.5-90.5 37.5-90.5 90.5-37.5 90.5 37.5 37.5 90.5zm1202 498q0 52-38 90t-90 38q-53 0-90.5-37.5t-37.5-90.5 37.5-90.5 90.5-37.5 90.5 37.5 37.5 90.5zm-964-996q0 66-47 113t-113 47-113-47-47-113 47-113 113-47 113 47 47 113zm1170 498q0 53-37.5 90.5t-90.5 37.5-90.5-37.5-37.5-90.5 37.5-90.5 90.5-37.5 90.5 37.5 37.5 90.5zm-640-704q0 80-56 136t-136 56-136-56-56-136 56-136 136-56 136 56 56 136zm530 206q0 93-66 158.5t-158 65.5q-93 0-158.5-65.5t-65.5-158.5q0-92 65.5-158t158.5-66q92 0 158 66t66 158z"></path>
            </svg>
          ) : null}
          {!!children && children}
        </button>
      )}
    </>
  );

Button.Primary = ({ className, ...props }) => (
  <Button {...props} className={classNames('poltio-btn-primary', className)} />
);
Button.Secondary = ({ className, ...props }) => (
  <Button
    {...props}
    className={classNames('poltio-btn-secondary', className)}
  />
);
Button.Success = ({ className, ...props }) => (
  <Button {...props} className={classNames('poltio-btn-success', className)} />
);
Button.Danger = ({ className, ...props }) => (
  <Button {...props} className={classNames('poltio-btn-danger', className)} />
);

Button.Sky = ({ className = '', ...props }) => (
  <Button
    {...props}
    className={classNames(
      'inline-flex items-center gap-x-1.5 rounded-md bg-sky-400 px-2.5 py-1.5 text-sm font-semibold text-white shadow-sm hover:bg-sky-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-sky-600',
      'disabled:pointer-events-none',
      className
    )}
  />
);

Button.Primary.displayName = 'ButtonPrimary';
Button.Secondary.displayName = 'ButtonSecondary';
Button.Success.displayName = 'ButtonSuccess';
Button.Danger.displayName = 'ButtonDanger';
Button.Sky.displayName = 'ButtonSky';

export { Button, HealthButton, SearchButton, NavButton };

export default Button;
